import config from "../config/config";
import { APPLICATION_TOKEN_ERROR } from "../constants/constants";
import { ApiWrapper, ApiResponse, TokenResponse } from "../utils/api-wrapper";

export interface PaginationMeta {
    page_limit: number;
    page_offset: number;
    total_pages: number;
}

export interface SurveyListResponse {
    surveys: {
        survey_id: string;
        survey_name: string;
        client_id: string;
        client_name: string;
        confirmit_pid: string;
        type_id: string;
        dataset_type: string;
        created_on: string;
        platform: string;
        requested_by: string;
        status: string;
    }[];
    total_count: number;
    meta: PaginationMeta;
}

export interface ThemeResponse {
    topic_id: string;
    topic_name: string;
    comment_count: number;
    mention_percentage: number;
    sentiment_breakdown: {
        positive: number;
        negative: number;
        neutral: number;
    };
}

export interface QuestionResponse {
    question_id: string;
    question_name: string;
    comment_count: number;
    mention_percentage: number;
    sentiment_breakdown: {
        positive: number;
        negative: number;
        neutral: number;
    };
}
export interface FeedbackRequest {
    feedback_subject: string[];
    description: string;
    image: string | null;
}
export interface FeedbackResponse {
    success: boolean;
}
export interface QuestionListResponse {
    id: string;
    name: string;
}

export interface SummarizedTextRequest {
    survey_id: string;
    question_id: string | string[];
    language_code?: string;
    summary_type: string | string[];
    content_type: string;
    summary_length_type: string;
    filters?: Record<string, string[]>;
}
export interface Summary {
    text: string;
    keywords: string[];
}
export interface SummarizedTextResponse {
    data: {
        question_id: string;
        question: string;
        overall_summary: Summary;
        positive_summary: Summary;
        negative_summary: Summary;
        neutral_summary: Summary;
    }[];
    metadata: {
        content_type: string;
        summary_length_type: string;
    };
}
export interface SentimentResponse {
    total_comments: number;
    sentiment_score: number;
    sentiment_data: {
        sentiment_id: string;
        name: string;
        count: number;
        percentage: number;
    }[];
}
export interface WordCount {
    word: string;
    count: number;
}
export interface AllThemesResponse {
    id: string;
    name: string;
}
export interface SurveyDetailsResponse {
    id: string;
    name: string;
    threshold: number;
    total_respondents: number;
    total_comments: number;
    demographic_filters: {
        [key: string]: {
            options: {
                column_value: string;
                display_name: string;
            }[];
            display_name: string;
        };
    };
}

export interface FilterPresetRequest {
    id?: string;
    name: string;
    demographic_filters: {
        [key: string]: {
            options: {
                column_value: string;
                display_name: string;
                is_applied: boolean | null;
            }[];
            display_name: string;
        };
    };
}
export interface FilterPresetResponse {
    id: string;
    name: string;
    demographic_filters: {
        [key: string]: {
            options: {
                column_value: string;
                display_name: string;
                is_applied: boolean | null;
            }[];
            display_name: string;
        };
    };
}

export interface DeleteFilterPresetResponse {
    success: boolean;
}

export interface UserPresetFilter {
    id: string;
    name: string;
    demographic_filters: {
        [key: string]: {
            options: {
                column_value: string;
                display_name: string;
                is_applied: boolean | null;
            }[];
            display_name: string;
        };
    };
}

export interface ValidateFiltersRequest {
    filters: {
        [key: string]: string[];
    };
}
export interface ValidateFiltersResponse {
    detail: string;
    total_comments: number;
    total_respondents: number;
}

export type UserPresetFilters = UserPresetFilter[];
export interface QuestionRequest {
    limit?: number;
    sortby?: string;
    filters?: Record<string, string[]>;
}

export interface SentimentBreakDownRequest {
    filters?: Record<string, string[]>;
}

export interface WordCloudRequest {
    limit: number;
    filter_by: string;
    filters?: Record<string, string[]>;
}
export interface CommentsRequest {
    page_limit: number;
    page_offset: number;
    filters?: Record<string, string[]>;
    question_id: string;
    language_code?: string;
}

export type SentimentOptions = "negative" | "neutral" | "positive";
export interface CommentStructure {
    id: string;
    topic_id: string;
    topic_name: string;
    question_id: string;
    answer_lang: string;
    translated_en_answer: string;
    answer: string;
    sentiment_id: string;
    sentiment_name: SentimentOptions;
    translation_status: string;
}
export interface CommentsResponse {
    comments: CommentStructure[];
    total_count: number;
    translation_status: string;
    meta: PaginationMeta;
}
export interface LanguageMap {
    [key: string]: string;
}

export interface ExportDataRequest {
    filters?: Record<string, string[]>;
}

export interface ExportResponse {
    url: string;
    wait_time?: number;
}

export interface User {
    id: string;
    full_name: string;
    email: string;
    role_name: string;
    survey_pids: string;
    status: "Active" | "Inactive";
    valid_till: string;
    created_on: string;
}
export interface UsersListResponse {
    total_users: number;
    data: User[];
}
export interface Role {
    id: string;
    name: string;
}
type RolesResponse = Role[];

export interface UserInput {
    email: string;
    full_name: string;
    role_id: string;
    valid_till: string;
}

export interface AddUserResponse {
    id: string;
    email: string;
    full_name: string;
    role_id: string;
    valid_till: string;
}

interface SurveyData {
    confirmit_pid: string;
    survey_name: string;
    client_id: string;
    client_name: string;
    created_on: string;
    survey_id: string;
}

export interface UserDetailsResponse {
    full_name: string;
    email: string;
    role_name: string;
    surveys: SurveyData[];
    valid_till: string;
    created_on: string;
    status: string;
    created_by: string;
    meta: PaginationMeta;
    total_count: number;
}

export interface ModifiedUserRequest {
    user_id: string;
    role_id?: string;
    valid_till?: string;
    status: boolean;
}

export interface AssignSurveyRequest {
    user_id: string;
    survey_id: string;
    default_filters?: Record<string, string[]>;
    threshold?: number;
    questions?: string;
}
export interface UserSurveyDetailsResponse {
    questions: QuestionListResponse[];
    threshold: number;
    default_filters: {
        [key: string]: {
            options: {
                column_value: string;
                display_name: string;
                is_applied: boolean | null;
            }[];
            display_name: string;
        };
    };
    assigned_by: string;
    user_details: UserDetailsResponse;
}

export interface UpdateSurveyDetailsRequest {
    user_id: string;
    survey_id: string;
    default_filters?: Record<string, string[]>;
    threshold?: number;
    questions?: string[];
}
export interface ResponseRate {
    total_respondents: number;
    total_completed: number;
}

export interface Survey {
    uuid: string;
    survey_name: string;
    survey_id: string;
    client_name: string;
    survey_type: string;
    status: string;
    created_on: string;
    response_rate: ResponseRate;
}

export interface CesSurveyResponse {
    count: number;
    surveys: Survey[];
}

export class ExtendedApiWrapper extends ApiWrapper {
    public async initialize(): Promise<TokenResponse | undefined> {
        try {
            return await this.fetchApplicationToken();
        } catch (error) {
            console.error(APPLICATION_TOKEN_ERROR, error);
        }
    }
    async getSurveyList(pageLimit: number, pageOffset: number, searchKey: string, etlStatus: string): Promise<ApiResponse<SurveyListResponse>> {
        return this.get(`/survey/all?page_limit=${pageLimit}&page_offset=${pageOffset}&search_key=${searchKey}&etl_status=${etlStatus}`);
    }
    async getWorkplaceThemes(surveyId: string, requestBody?: QuestionRequest): Promise<ApiResponse<ThemeResponse>> {
        const url = `/response/${surveyId}/theme-sentiment-breakdown`;
        return this.post(url, requestBody ?? {});
    }
    async getAllQuestions(surveyId: string, requestBody?: QuestionRequest): Promise<ApiResponse<QuestionResponse>> {
        const url = `/response/${surveyId}/question`;
        return this.post(url, requestBody ?? {});
    }

    async sendFeedback(requestBody: FeedbackRequest): Promise<ApiResponse<FeedbackResponse>> {
        return this.post<FeedbackResponse>("/feedback/add", requestBody);
    }
    async getQuestionsList(surveyId: string): Promise<ApiResponse<QuestionListResponse[]>> {
        return this.get(`/survey/${surveyId}/questions`);
    }
    async getSummarizedText(data: SummarizedTextRequest): Promise<ApiResponse<SummarizedTextResponse>> {
        return this.post(`/response/${data.survey_id}/summarized-text`, data);
    }
    async getSentimentBreakdown(surveyId: string, requestBody?: SentimentBreakDownRequest): Promise<ApiResponse<SentimentResponse>> {
        return this.post(`/response/${surveyId}/sentiment-breakdown`, requestBody ?? {});
    }
    async getGenericWordCloud(surveyId: string): Promise<ApiResponse<WordCount[]>> {
        return this.get(`/response/${surveyId}/wordcloud?limit=100`);
    }
    async getAllThemes(surveyId: string, requestBody?: SentimentBreakDownRequest): Promise<ApiResponse<AllThemesResponse[]>> {
        return this.post(`/response/${surveyId}/themes`, requestBody ?? {});
    }
    async getWordCloud(surveyId: string, requestBody: WordCloudRequest): Promise<ApiResponse<WordCount[]>> {
        return this.post(`/response/${surveyId}/wordcloud`, requestBody);
    }
    async getSurveyDetails(surveyId: string): Promise<ApiResponse<SurveyDetailsResponse>> {
        return this.get(`/survey/${surveyId}`);
    }
    async updateFilterPreset(surveyId: string, requestBody: FilterPresetRequest): Promise<ApiResponse<FilterPresetResponse>> {
        const url = `/survey/${surveyId}/filter_preset`;
        return this.put<FilterPresetResponse>(url, requestBody);
    }
    async getUserFilterPreset(surveyId: string): Promise<ApiResponse<UserPresetFilters>> {
        return this.get(`/survey/${surveyId}/filter_preset`);
    }
    async deleteFilterPreset(surveyId: string, presetId: string): Promise<ApiResponse<DeleteFilterPresetResponse>> {
        const url = `/survey/${surveyId}/filter_preset/${presetId}`;
        return this.delete<DeleteFilterPresetResponse>(url);
    }
    async validateFilters(data: { survey_id: string; filters: ValidateFiltersRequest }): Promise<ApiResponse<ValidateFiltersResponse>> {
        return this.post(`/survey/${data.survey_id}/validate_filters`, data.filters);
    }
    async getSurveyComments(surveyId: string, data: CommentsRequest): Promise<ApiResponse<CommentsResponse>> {
        const url = `/response/${surveyId}/comments`;
        return this.post(url, data);
    }
    async getLanguages(): Promise<ApiResponse<LanguageMap>> {
        return this.get(`/utilities/language_codes`);
    }
    async exportData(surveyId: string, requestBody: ExportDataRequest): Promise<ApiResponse<ExportResponse>> {
        return this.post(`/response/${surveyId}/export_data`, requestBody);
    }
    async listOfUsers(pageLimit: number, pageOffset: number, searchKey: string): Promise<ApiResponse<UsersListResponse>> {
        return this.get(`/admin/users?page_limit=${pageLimit}&page_offset=${pageOffset}&search_key=${searchKey}`);
    }
    async getRoles(): Promise<ApiResponse<RolesResponse>> {
        return this.get(`/admin/roles`);
    }
    async addUser(requestBody: UserInput): Promise<ApiResponse<AddUserResponse>> {
        return this.post(`/admin/user`, requestBody);
    }
    async deleteUser(userId: string): Promise<ApiResponse<object>> {
        return this.delete(`/admin/user/${userId}`);
    }
    async fetchUserDetails(userId: string, pageLimit: number, pageOffset: number): Promise<ApiResponse<UserDetailsResponse>> {
        return this.get(`/admin/user/${userId}?page_limit=${pageLimit}&page_offset=${pageOffset}`);
    }
    async updateUserDetails(requestBody: ModifiedUserRequest): Promise<ApiResponse<object>> {
        return this.put(`/admin/user`, requestBody);
    }
    async assignSurvey(requestBody: AssignSurveyRequest): Promise<ApiResponse<object>> {
        return this.post(`/admin/user-survey`, requestBody);
    }
    async getUserSurveyDetails(userId: string, surveyId: string): Promise<ApiResponse<UserSurveyDetailsResponse>> {
        return this.get(`/admin/user-survey?user_id=${userId}&survey_id=${surveyId}`);
    }
    async updateUserSurveyDetails(requestBody: UpdateSurveyDetailsRequest): Promise<ApiResponse<object>> {
        return this.put(`/admin/user-survey`, requestBody);
    }
    async deleteUserSurvey(userId: string, surveyId: string): Promise<ApiResponse<object>> {
        return this.delete(`/admin/user-survey?user_id=${userId}&survey_id=${surveyId}`);
    }
    async searchCesSurvey(searchKey: string): Promise<ApiResponse<CesSurveyResponse>> {
        return this.get(`/admin/ces-survey?search_key=${searchKey}`);
    }
    async RequestCesSurveyProcess(requestBody: Survey): Promise<ApiResponse<object>> {
        return this.post(`/admin/request-ces-survey`, requestBody);
    }
}

export const extendedApiWrapper = new ExtendedApiWrapper(`${config.app.REACT_APP_BASE_URL}` || "");
