import React from "react";

import Requested from "../../assets/blue-check.svg";
import Check from "../../assets/check.svg";
import Failed from "../../assets/failed.svg";
import Processing from "../../assets/processing.svg";

import styles from "./styles.module.css";

interface TagProps {
    label: string;
    status: "successful" | "failed" | "processing" | "requested";
}

const SurveyStatusTag: React.FC<TagProps> = ({ label, status }) => {
    let iconSrc: string;
    let altText: string;

    switch (status) {
        case "successful":
            iconSrc = Check;
            altText = "Success";
            break;
        case "failed":
            iconSrc = Failed;
            altText = "Failed";
            break;
        case "processing":
            iconSrc = Processing;
            altText = "Processing";
            break;
        case "requested":
            iconSrc = Requested;
            altText = "Requested";
            break;
        default:
            iconSrc = Processing;
            altText = "Unknown";
    }

    return (
        <div className={`${styles.tag} ${styles[status]}`}>
            <img src={iconSrc} alt={altText} className={styles.icon} />
            <span>{label}</span>
        </div>
    );
};

export default SurveyStatusTag;
