import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ActiveFilter from "../../assets/active_filter.svg";
import FilterIcon from "../../assets/filter.svg";
import { SENTIMENT_COLORS, SUMMARY_READY } from "../../constants/constants";
import { extendedApiWrapper, SentimentResponse } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { hideSnackbar } from "../../store/snackbar-slice";
import TimeoutDialog from "../dialog/timeout-dialog";
import Filter from "../filter/filter";
import ToastNotification from "../snackbar/snackbar";

import GaugeChart from "./gauge-chart";
import LinkTab from "./link-tab";
import PieChart from "./pie-chart";
import Questions from "./questions-overview";
import Sentiment from "./sentiment";
import styles from "./styles.module.css";
import TextSummary from "./text-summary";
import Themes from "./themes";
import WordCloud from "./wordcloud";
import Wrapper from "./wrapper";

const tabs = [
    {
        label: "Themes",
        href: "/themes",
        content: <Themes />,
    },
    {
        label: "Questions",
        href: "/wordcloud",
        content: <Questions />,
    },
];

const summaryTabs = [
    {
        label: "Text Summary",
        href: "/text-summary",
        content: <TextSummary />,
    },
    {
        label: "Wordcloud",
        href: "/wordcloud",
        content: <WordCloud />,
    },
];

const Overview = () => {
    const [sentimentData, setSentimentData] = useState<SentimentResponse | null>(null);
    const [selectedSummaryTab, setSelectedSummaryTab] = useState(0);
    const [selectedDetailTab, setSelectedDetailTab] = useState(0);
    const [unauthorized, setUnauthorized] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const filters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    const displaySnackbar = useAppSelector((state) => state.snackbar.open);

    const handleSummaryTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedSummaryTab(newValue);
    };

    const handleDetailTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedDetailTab(newValue);
    };

    const fetchSentimentBreakdown = async (appliedFilters: Record<string, string[]> | null = null) => {
        if (!selectedSurveyId) return;
        try {
            const requestBody = appliedFilters ? { filters: appliedFilters } : { filters };
            const response = await extendedApiWrapper.getSentimentBreakdown(selectedSurveyId, requestBody);
            if (response.data) {
                setSentimentData(response.data);
                setFiltersApplied(!!appliedFilters || Object.keys(filters).length > 0);
            } else if (response.status === 401) {
                setUnauthorized(true);
            }
        } catch (err) {
            console.error("Failed to fetch sentiment data.");
        }
    };
    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            fetchSentimentBreakdown(filters);
        } else {
            fetchSentimentBreakdown(); // Call without filters if none are present
        }
    }, [selectedSurveyId, filters]);

    const handleSummaryRoute = () => {
        navigate("../text-summarisation", { replace: true });
    };

    const handleRoute = () => {
        if (tabs[selectedDetailTab].label === "Themes") {
            navigate("../workplace-themes");
        } else {
            navigate("../questions");
        }
    };
    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };

    const handleFilterOpen = () => {
        setIsOpen(true);
    };
    const handleFilterClose = () => {
        setIsOpen(false);
    };

    const onApplyFilter = (filters: { selected: Record<string, string[]> }) => {
        fetchSentimentBreakdown(filters.selected);
        handleFilterClose();
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.content}>Overview</div>
                <button className={styles.filter} onClick={handleFilterOpen}>
                    <img src={filtersApplied ? ActiveFilter : FilterIcon} alt="filter" width={16} height={16} />
                    <span>Filters</span>
                </button>
            </div>
            <div style={{ height: "100vh" }}>
                <div className={styles.sentimentBreakDownContainer}>
                    <Wrapper style={{ width: "52.75rem", flexDirection: "column" }}>
                        <div className={styles.sentimentBreakdownText}>Sentiment comments breakdown</div>
                        <div className={styles.chartContainer}>
                            <div style={{ width: "28.25rem" }}>{sentimentData && <PieChart sentimentData={sentimentData} />}</div>
                            <div className={styles.sentiments}>
                                {sentimentData?.sentiment_data.map((sentiment) => (
                                    <div className={styles.gridKnot} key={sentiment.sentiment_id}>
                                        <Sentiment dotColor={SENTIMENT_COLORS[sentiment.name]} sentimentText={sentiment.name} number={sentiment.count} showDot />
                                    </div>
                                ))}
                                <div className={styles.gridKnot}>
                                    <Sentiment dotColor="" sentimentText="Total" number={sentimentData?.total_comments ?? 0} showDot={false} />
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                    <Wrapper style={{ width: "35%", flexDirection: "column" }}>
                        <div className={styles.sentimentBreakdownText}>Sentiment Score</div>
                        <div className={styles.sentimentScore}>{sentimentData?.sentiment_score}</div>
                        <hr className={styles.separator} />
                        <GaugeChart percent={sentimentData?.sentiment_score ?? 0} />
                    </Wrapper>
                </div>
                <div style={{ display: "flex", height: "fit-content", marginBottom: "30px" }}>
                    <Wrapper showButton onClick={handleSummaryRoute}>
                        <LinkTab tabs={summaryTabs} value={selectedSummaryTab} onChange={handleSummaryTabChange} />
                    </Wrapper>
                </div>
                <div style={{ display: "flex", height: "fit-content" }}>
                    <Wrapper showButton onClick={handleRoute} style={{ marginBottom: "30px" }}>
                        <LinkTab tabs={tabs} value={selectedDetailTab} onChange={handleDetailTabChange} />
                    </Wrapper>
                </div>
            </div>
            <ToastNotification open={displaySnackbar} onClose={() => dispatch(hideSnackbar())} message={SUMMARY_READY.text} title={SUMMARY_READY.title} />
            <Filter open={isOpen} handleFilterClose={handleFilterClose} onApplyFilter={onApplyFilter} />
            {unauthorized && <TimeoutDialog open={unauthorized} handleClick={handleClose} />}
        </div>
    );
};
export default Overview;
