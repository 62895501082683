import { DialogContent, Box } from "@mui/material";

import * as React from "react";

import Close from "../../assets/close_icon.svg";
import Warning from "../../assets/red-warning.svg";

import { CustomDialogActions, CustomCancelButton, CustomTranslateButton, ConfirmationDialog } from "../../styled/mui-styled";

import styles from "./styles.module.css";

interface BootstrapDialogProps {
    open: boolean;
    onClose: () => void;
    handleCancel?: () => void;
    handleRemove?: () => void;
    highlightedText: string;
    text: {
        title?: string;
        firstMessage?: string;
        lastMessage?: string;
        confirmation?: string;
    };
    removeText: string;
}

const DeleteDialog: React.FC<BootstrapDialogProps> = ({ open, onClose, handleRemove, handleCancel, highlightedText, text, removeText }) => {
    return (
        <ConfirmationDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
            <button className={styles.close} onClick={onClose}>
                <img src={Close} alt="Close" width={16} height={16} />
            </button>
            <DialogContent>
                <Box className={styles.content}>
                    <Box>
                        <img src={Warning} alt="warning" loading="lazy" />
                    </Box>
                    <Box className={styles.text} sx={{ marginBottom: "1rem" }}>
                        {text.title}
                    </Box>
                    <Box className={styles.subText}>
                        {text.firstMessage} <span className={styles.languageText}>&ldquo;{highlightedText}&rdquo;</span> {text.lastMessage}
                    </Box>
                    <Box className={styles.subText}>{text.confirmation}</Box>
                </Box>
            </DialogContent>

            <CustomDialogActions>
                <CustomCancelButton variant="contained" onClick={handleCancel}>
                    Cancel
                </CustomCancelButton>
                <CustomTranslateButton variant="contained" onClick={handleRemove}>
                    {removeText}
                </CustomTranslateButton>
            </CustomDialogActions>
        </ConfirmationDialog>
    );
};

export default DeleteDialog;
