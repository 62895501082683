import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { SURVEY_LIST_LIMIT } from "../../constants/constants";
import { extendedApiWrapper } from "../../services/api-handlers";
import { useAppDispatch } from "../../store/hooks";
import { setRoles } from "../../store/roles-slice";
import { fetchSurveys } from "../../store/survey-slice";
import ManageUsers from "../manage-users/manage-users";
import SurveyStatus from "../survey-status/survey-status";

import NavBar from "./navbar";
import styles from "./styles.module.css";

const UserManagement = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await extendedApiWrapper.getRoles();
                if (response.data) {
                    dispatch(setRoles(response.data));
                } else {
                    console.error("Failed to fetch roles:", response.error);
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
            }
        };

        fetchRoles();
    }, []);

    useEffect(() => {
        dispatch(fetchSurveys({ limit: SURVEY_LIST_LIMIT, pageOffset: 0, searchKey: "", etlStatus: "" }));
    }, []);

    return (
        <div className={styles.container}>
            <NavBar />
            <div className={styles.routeContainer}>
                <Routes>
                    <Route path="*" element={<Navigate to="manage-users" replace />} />
                    <Route path="manage-users" element={<ManageUsers />} />
                    <Route path="/survey-status" element={<SurveyStatus />} />
                </Routes>
            </div>
        </div>
    );
};
export default UserManagement;
