import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { extendedApiWrapper, SurveyListResponse } from "../services/api-handlers";

interface SurveyState {
    surveys: SurveyListResponse["surveys"];
    totalCount: number;
    isLoading: boolean;
    error: boolean;
    selectedSurveyId: string | null;
    currentPageOffset: number;
    status: number | null;
}

const initialState: SurveyState = {
    surveys: [],
    totalCount: 0,
    isLoading: false,
    error: false,
    selectedSurveyId: null,
    currentPageOffset: 0,
    status: null,
};

export const fetchSurveys = createAsyncThunk(
    "surveys/fetchSurveys",
    async ({ limit, searchKey, pageOffset, etlStatus }: { limit: number; searchKey: string; pageOffset: number; etlStatus: string }) => {
        try {
            const response = await extendedApiWrapper.getSurveyList(limit, pageOffset, searchKey, etlStatus);
            if (response.status && response.status >= 200 && response.status <= 300) {
                if (!response.data) {
                    throw new Error("Oops! Something went wrong while fetching the survey list.");
                }
                return { data: response.data, status: response.status };
            } else {
                return { data: null, status: response.status };
            }
        } catch (error) {
            throw error instanceof Error ? error : new Error("An unexpected error occurred.");
        }
    },
);

const surveySlice = createSlice({
    name: "surveys",
    initialState,
    reducers: {
        setSelectedSurveyId: (state, action) => {
            state.selectedSurveyId = action.payload;
        },
        setPageOffset: (state, action) => {
            state.currentPageOffset = action.payload;
        },
        resetSurveyState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSurveys.pending, (state) => {
                state.isLoading = true;
                state.error = false;
            })
            .addCase(fetchSurveys.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.data) {
                    state.surveys = action.payload.data.surveys;
                    state.totalCount = action.payload.data.total_count;
                } else {
                    state.error = true;
                }
                state.status = action.payload.status ?? null;
            })
            .addCase(fetchSurveys.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                console.error(action.error.message);
            });
    },
});

export const { setSelectedSurveyId, setPageOffset, resetSurveyState } = surveySlice.actions;
export default surveySlice.reducer;
