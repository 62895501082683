/* eslint-disable @typescript-eslint/no-unused-vars */
import { Snackbar, Alert, CircularProgress, Pagination } from "@mui/material";

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import AssignIcon from "../../assets/assign.svg";
import Avatar from "../../assets/avatar.svg";
import EditIcon from "../../assets/blue_edit.svg";
import Calendar from "../../assets/calendar.svg";
import DropDownIcon from "../../assets/down_arrow.svg";
import Mail from "../../assets/mail.svg";
import Role from "../../assets/role.svg";
import SaveIcon from "../../assets/save_changes.svg";
import User from "../../assets/user.svg";
import {
    DELETE_USER_DIALOG_TEXT,
    REMOVE_SURVEY_DIALOG_TEXT,
    UPDATE_USER_SUCCESS_MSG,
    UPDATE_USER_FAILURE_MSG,
    ERROR_MSG,
    DELETE_USER_ERROR,
    DELETE_USER_MSG,
    BREADCRUMB_ITEMS,
    SURVEY_ASSIGNED_ERR,
    SURVEY_ASSIGNED_MSG,
    NO_ASSIGNED_SURVEY,
    DELETE_USER_SURVEY_MSG,
    DELETE_USER_SURVEY_ERROR,
    USERS_LIST_LIMIT,
    SURVEY_LIST_LIMIT,
} from "../../constants/constants";
import { extendedApiWrapper, UserDetailsResponse, ModifiedUserRequest, AssignSurveyRequest } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { fetchSurveys } from "../../store/survey-slice";
import { formatDate, formatTimestamp } from "../../utils/utility-function";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Button from "../button/button";
import CustomCard, { CardItem } from "../card/custom-card";
import AssignSurveyDialog, { Option } from "../dialog/assign-survey-dialog";
import DeleteDialog from "../dialog/delete-dialog";
import TimeoutDialog from "../dialog/timeout-dialog";
import MessageCard from "../message-card/message-card";
import UserTable from "../table/user-table";

import styles from "./styles.module.css";
import UserSurvey from "./user-survey";

interface Surveys {
    confirmit_pid: string;
    survey_name: string;
    client_name: string;
    survey_id?: string;
}

interface Column<T> {
    header: string;
    key: keyof T;
    render?: (value: T[keyof T], row: T) => React.ReactNode;
    sortable?: boolean;
}

const columns: Column<Surveys>[] = [
    { header: "Survey ID", key: "confirmit_pid" },
    { header: "Survey Name", key: "survey_name" },
    { header: "Client Name", key: "client_name" },
];

interface UserDetailsProps {
    userId: string | null;
}

const UserDetails: React.FC<UserDetailsProps> = ({ userId }) => {
    const [userDetails, setUserDetails] = useState<UserDetailsResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [userSurveyView, setUserSurveyView] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [userData, setUserData] = useState<CardItem[]>([]);
    const [initialUserData, setInitialUserData] = useState<CardItem[]>([]);
    const [selectedSurveyId, setSelectedSurveyId] = useState<string | number>();
    const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [unauthorized, setUnauthorized] = useState<boolean>(false);
    const [dialogConfig, setDialogConfig] = useState<{
        open: boolean;
        text: {
            title?: string;
            firstMessage?: string;
            lastMessage?: string;
            confirmation?: string;
        };
        highlightedText: string;
        removeText: string;
        onConfirm?: () => void;
    }>({
        open: false,
        text: {
            title: "",
            firstMessage: "",
            lastMessage: "",
            confirmation: "",
        },
        highlightedText: "",
        removeText: "",
    });

    const dispatch = useAppDispatch();
    const roles = useAppSelector((state) => state.roles.roles);
    const surveys = useAppSelector((state) => state.surveys.surveys);
    const navigate = useNavigate();
    const userSurveys = useMemo<Surveys[]>(() => {
        if (!userDetails?.surveys) return [];
        return userDetails.surveys.map((survey) => ({
            confirmit_pid: survey.confirmit_pid || "",
            survey_name: survey.survey_name || "",
            client_name: survey.client_name || "",
            survey_id: survey.survey_id || "",
        }));
    }, [userDetails]);

    const options = surveys.map((survey) => ({
        id: survey.survey_id,
        name: `${survey.confirmit_pid} - ${survey.survey_name}`,
    }));

    const openDeleteDialog = (text: { title?: string; firstMessage?: string; lastMessage?: string; confirmation?: string }, highlightedText: string, removeText: string, onConfirm: () => void) => {
        setDialogConfig({ open: true, text, highlightedText, removeText, onConfirm });
    };

    const handleCloseDialog = () => {
        setDialogConfig({ ...dialogConfig, open: false });
    };

    const fetchUserDetails = async (userId: string): Promise<void> => {
        setLoading(true);
        setError(false);
        try {
            const response = await extendedApiWrapper.fetchUserDetails(userId, USERS_LIST_LIMIT, page - 1);
            if (response.data) {
                setUserDetails(response.data);
                const formattedData = [
                    { icon: Avatar, label: "Name", value: response.data.full_name || "" },
                    { icon: Mail, label: "Email Id", value: response.data.email || "" },
                    { icon: Role, label: "Role", value: response.data.role_name || "", isDropdown: true, dropdownType: "menu", dropdownOptions: roles.map((role) => role.name) },
                    { icon: User, label: "User created on", value: formatDate(response.data.created_on) },
                    { icon: AssignIcon, label: "User created by", value: response.data.created_by || "" },
                    { icon: Calendar, label: "User valid till", value: formatDate(response.data.valid_till), isDropdown: true, dropdownType: "calendar" },
                ] as CardItem[];
                setUserData(formattedData);
                setInitialUserData(JSON.parse(JSON.stringify(formattedData)));
            } else if (response.status === 401) {
                setUnauthorized(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveSurvey = (row: Surveys) => {
        openDeleteDialog(REMOVE_SURVEY_DIALOG_TEXT, `${row.confirmit_pid} - ${row.survey_name}`, "Remove", async () => {
            if (!userId || !row.survey_id) return;
            try {
                const response = await extendedApiWrapper.deleteUserSurvey(userId, row.survey_id);

                if (response.data) {
                    setSnackbarMessage(DELETE_USER_SURVEY_MSG);
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    // Fetch user details again to update the view after deleting the survey
                    fetchUserDetails(userId);
                } else {
                    setSnackbarMessage(DELETE_USER_SURVEY_ERROR);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            } catch (error) {
                setSnackbarMessage(DELETE_USER_SURVEY_ERROR);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
            handleCloseDialog();
        });
    };

    useEffect(() => {
        if (userId) {
            fetchUserDetails(userId);
        }
    }, [userId, page]);

    useEffect(() => {
        dispatch(fetchSurveys({ limit: SURVEY_LIST_LIMIT, pageOffset: 0, searchKey: "", etlStatus: "" }));
    }, []);

    const handleRemoveUser = async () => {
        const userName = userData.find((item) => item.label === "Name")?.value;
        const userString = userName instanceof Date ? userName.toString() : userName || "User";

        openDeleteDialog(DELETE_USER_DIALOG_TEXT, String(userString), "Delete", async () => {
            if (userId) {
                try {
                    const response = await extendedApiWrapper.deleteUser(userId);
                    if (response.data) {
                        setSnackbarMessage(DELETE_USER_MSG);
                        setSnackbarSeverity("success");
                    } else {
                        setSnackbarMessage(DELETE_USER_ERROR);
                        setSnackbarSeverity("error");
                    }
                } catch (error) {
                    setSnackbarMessage(DELETE_USER_ERROR);
                    setSnackbarSeverity("error");
                } finally {
                    setSnackbarOpen(true);
                }
            }
            handleCloseDialog();
            navigate("./manage-users");
        });
    };

    const handleView = (row: Surveys) => {
        setSelectedSurveyId(row.survey_id);
        setUserSurveyView(true);
    };

    const handleSaveChanges = async () => {
        setError(false);

        const selectedRoleName = userData.find((item) => item.label === "Role")?.value; // Role name from userData

        const selectedDate = userData.find((item) => item.label === "User valid till")?.value; // Valid till date

        if (!userId) return;

        const selectedRole = roles.find((role) => role.name === String(selectedRoleName).toLowerCase()); // Assuming roles contain { name, id }

        let formattedDate: string | undefined;
        if (selectedDate instanceof Date) {
            formattedDate = formatTimestamp(selectedDate);
        } else if (typeof selectedDate === "string") {
            const date = new Date(selectedDate);
            if (!isNaN(date.getTime())) {
                formattedDate = formatTimestamp(date);
            }
        }

        const requestBody: ModifiedUserRequest = {
            user_id: userId,
            role_id: selectedRole?.id,
            valid_till: formattedDate,
            status: true,
        };

        try {
            const response = await extendedApiWrapper.updateUserDetails(requestBody);
            if (response.data) {
                setInitialUserData([...userData]);
                setUserData([...userData]);
                setSnackbarMessage(UPDATE_USER_SUCCESS_MSG);
                setSnackbarSeverity("success");
                fetchUserDetails(userId);
            } else {
                setError(true);
                setUserData((prevState) => [...initialUserData]);
                setSnackbarMessage(UPDATE_USER_FAILURE_MSG);
                setSnackbarSeverity("error");
            }
        } catch (error) {
            setError(true);
            setUserData((prevState) => [...initialUserData]);
            setSnackbarMessage(UPDATE_USER_FAILURE_MSG);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDropdownChange = (index: number, value: string | Date) => {
        const updatedItems = [...userData];
        updatedItems[index].value = value;
        setUserData(updatedItems);
    };

    const handleAssignDialog = () => {
        setOpenAssignDialog(true);
    };

    const handleClose = () => {
        setOpenAssignDialog(false);
    };

    const handleSelectChange = (values: Option[]) => {
        setSelectedSurveyId(values[0].id);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleAssignSurvey = async () => {
        if (!userId || !selectedSurveyId) return;

        const requestBody: AssignSurveyRequest = {
            user_id: userId,
            survey_id: String(selectedSurveyId),
        };

        try {
            const response = await extendedApiWrapper.assignSurvey(requestBody);

            if (response.data) {
                setOpenAssignDialog(false);
                setSnackbarMessage(SURVEY_ASSIGNED_MSG);
                setSnackbarSeverity("success");
                fetchUserDetails(userId);
            } else {
                setSnackbarMessage(SURVEY_ASSIGNED_ERR);
                setSnackbarSeverity("error");
            }
        } catch (error) {
            setSnackbarMessage(SURVEY_ASSIGNED_ERR);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };
    const handleAppRefresh = () => {
        setUnauthorized(false);
        navigate(0);
    };
    return (
        <div>
            {userSurveyView ? (
                <UserSurvey userId={userId} selectedSurveyId={String(selectedSurveyId)} />
            ) : (
                <>
                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb items={BREADCRUMB_ITEMS} />

                        {!loading && (
                            <div className={styles.deleteButtonContainer}>
                                <Button label="Delete User" variant="secondary" onClick={handleRemoveUser} />
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div className={styles.loader}>
                            <CircularProgress size={50} />
                        </div>
                    )}
                    {!loading && error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}
                    {!loading && !error && (
                        <>
                            <div>
                                <CustomCard
                                    items={userData}
                                    editIcon={EditIcon}
                                    dropdownIcon={DropDownIcon}
                                    saveIcon={SaveIcon}
                                    onSaveChanges={handleSaveChanges}
                                    onDropdownChange={handleDropdownChange}
                                    height="12.5rem"
                                />
                            </div>
                            <div className={styles.surveyContainer}>
                                <div className={styles.surveyText}>Assigned Survey</div>
                                <div className={styles.assignButton}>
                                    {" "}
                                    <Button label="Assign Survey" onClick={handleAssignDialog} disabled={userDetails?.role_name === "ADMIN" || userDetails?.role_name === "SUPER_ADMIN"} />
                                </div>
                            </div>

                            <div>
                                {userSurveys.length === 0 ? (
                                    <div className={styles.noSurvey}>{NO_ASSIGNED_SURVEY}</div>
                                ) : (
                                    <UserTable
                                        columns={columns}
                                        data={userSurveys}
                                        onDelete={handleRemoveSurvey}
                                        onView={handleView}
                                        viewText="VIEW DETAILS"
                                        showViewColumn={userDetails?.role_name !== "ADMIN" && userDetails?.role_name !== "SUPER_ADMIN"}
                                        showDeleteColumn={userDetails?.role_name !== "ADMIN" && userDetails?.role_name !== "SUPER_ADMIN"}
                                    />
                                )}
                            </div>
                            <div className={styles.pagination}>
                                {userDetails && userDetails.total_count > USERS_LIST_LIMIT && (
                                    <Pagination
                                        sx={{
                                            "& .MuiPaginationItem-root.Mui-selected": {
                                                background: "#E6F2F9",
                                            },
                                        }}
                                        count={Math.ceil(userDetails.total_count / USERS_LIST_LIMIT)}
                                        page={page}
                                        onChange={handlePageChange}
                                        showFirstButton
                                        showLastButton
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {dialogConfig.open && (
                        <DeleteDialog
                            open={dialogConfig.open}
                            handleCancel={handleCloseDialog}
                            onClose={handleCloseDialog}
                            highlightedText={dialogConfig.highlightedText}
                            text={dialogConfig.text}
                            handleRemove={dialogConfig.onConfirm}
                            removeText={dialogConfig.removeText}
                        />
                    )}
                    {openAssignDialog && (
                        <AssignSurveyDialog
                            open={openAssignDialog}
                            handleCancel={handleClose}
                            onClose={handleClose}
                            options={options}
                            handleSelectChange={handleSelectChange}
                            handleAssign={handleAssignSurvey}
                        />
                    )}
                    <TimeoutDialog open={unauthorized} handleClick={handleAppRefresh} />
                    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </div>
    );
};
export default UserDetails;
