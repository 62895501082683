/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pagination, CircularProgress, Snackbar, Alert } from "@mui/material";
import debounce from "lodash/debounce";

import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import RefreshIcon from "../../assets/refresh.svg";
import SearchIcon from "../../assets/search_icon.svg";
import {
    SURVEY_STATUS_SEARCH_PLACEHOLDER,
    SURVEY_STATUS_PAGE_LIMIT,
    ERROR_MSG,
    NO_DATA_MSG,
    NO_DATA_CES_SURVEY_SEARCH,
    CES_SURVEY_SEARCH_ERR,
    PROCESSING,
    ALREADY_PROCESSING,
    PROCESSING_ERROR,
} from "../../constants/constants";
import { extendedApiWrapper, Survey } from "../../services/api-handlers";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { fetchSurveys, resetSurveyState } from "../../store/survey-slice";
import { formatDate, mapStatusToType } from "../../utils/utility-function";

import Button from "../button/button";
import CesSurveyDialog from "../dialog/ces-survey-dialog";
import TimeoutDialog from "../dialog/timeout-dialog";
import SearchInput from "../input/search-input";
import MessageCard from "../message-card/message-card";
import UserTable, { Column } from "../table/user-table";
import SurveyStatusTag from "../tags/survey-status-tags";

import styles from "./styles.module.css";

const columns: Column<{
    surveyId: string;
    surveyName: string;
    clientName: string;
    status: string;
    platform: string;
    createdOn: string;
    requestedBy: string;
}>[] = [
    { header: "Survey ID", key: "surveyId" },
    { header: "Survey Name", key: "surveyName" },
    { header: "Client Name", key: "clientName" },
    {
        header: "Status",
        key: "status",
        sortable: false,
        render: (value: string) => {
            const statusType = mapStatusToType(value);
            return <SurveyStatusTag label={value} status={statusType} />;
        },
    },
    { header: "Platform", key: "platform" },
    { header: "Created On", key: "createdOn" },
    { header: "Requested By", key: "requestedBy" },
];

const SurveyStatus: React.FC = () => {
    const dispatch = useAppDispatch();
    const { surveys, totalCount, isLoading, error } = useAppSelector((state) => state.surveys);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [open, setOpen] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<Survey[]>([]);
    const [searchError, setSearchError] = useState("");
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [unauthorized, setUnauthorized] = useState<boolean>(false);

    const navigate = useNavigate();

    const transformedSurveys = surveys.map((survey: any) => ({
        surveyId: survey.confirmit_pid,
        surveyName: survey.survey_name,
        clientName: survey.client_name,
        status: survey.etl_status,
        platform: survey.platform ?? "N/A",
        createdOn: formatDate(survey.created_on),
        requestedBy: survey.requested_by ?? "N/A",
    }));

    useEffect(() => {
        return () => {
            dispatch(resetSurveyState());
        };
    }, []);

    const debouncedFetchSurveys = useCallback(
        debounce((pageOffset, search) => {
            dispatch(fetchSurveys({ limit: SURVEY_STATUS_PAGE_LIMIT, pageOffset, searchKey: search, etlStatus: "all" }));
        }, 500),
        [dispatch],
    );

    useEffect(() => {
        debouncedFetchSurveys(currentPage - 1, searchKey);
        return () => {
            debouncedFetchSurveys.cancel();
        };
    }, [currentPage, searchKey, debouncedFetchSurveys]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setSearchKey(value);
        setCurrentPage(1);
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };
    const handleRefresh = () => {
        dispatch(fetchSurveys({ limit: SURVEY_STATUS_PAGE_LIMIT, pageOffset: currentPage - 1, searchKey, etlStatus: "all" }));
    };
    const handleSurveySearch = async (surveyId: string) => {
        setSearchError("");
        try {
            const response = await extendedApiWrapper.searchCesSurvey(surveyId);
            if (response.data) {
                const { surveys } = response.data;
                if (surveys.length > 0) {
                    const matchedSurvey = surveys.find((survey: any) => survey.survey_id === surveyId.toLowerCase());
                    if (matchedSurvey) {
                        setSearchResults([matchedSurvey]);
                    } else {
                        setSearchResults([]);
                        setSearchError(NO_DATA_CES_SURVEY_SEARCH);
                    }
                } else {
                    setSearchResults([]);
                    setSearchError(NO_DATA_CES_SURVEY_SEARCH);
                }
            } else {
                setSearchError(CES_SURVEY_SEARCH_ERR);
            }
        } catch (error) {
            setSearchError(CES_SURVEY_SEARCH_ERR);
        }
    };

    const handleCesSurveyProcess = async () => {
        try {
            for (const survey of searchResults) {
                const requestBody = {
                    uuid: survey.uuid,
                    survey_name: survey.survey_name,
                    survey_id: survey.survey_id,
                    client_name: survey.client_name,
                    survey_type: survey.survey_type,
                    status: survey.status,
                    created_on: survey.created_on,
                    response_rate: survey.response_rate,
                };

                const response = await extendedApiWrapper.RequestCesSurveyProcess(requestBody);

                if (response.data) {
                    setSnackbarSeverity("success");
                    setSnackbarMessage(PROCESSING);
                    setSearchResults([]);
                    dispatch(fetchSurveys({ limit: SURVEY_STATUS_PAGE_LIMIT, pageOffset: currentPage - 1, searchKey, etlStatus: "all" }));
                } else if (response.status === 400) {
                    setSearchResults([]);
                    setSnackbarMessage(ALREADY_PROCESSING);
                    setSnackbarSeverity("error");
                } else {
                    setSearchResults([]);
                    setSnackbarMessage(PROCESSING_ERROR);
                    setSnackbarSeverity("error");
                }
            }
            setOpen(false);
        } catch (error) {
            setSearchResults([]);
            setSnackbarMessage(PROCESSING_ERROR);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleAppRefresh = () => {
        setUnauthorized(false);
        navigate(0);
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <div className={styles.search}>
                    <SearchInput placeholder={SURVEY_STATUS_SEARCH_PLACEHOLDER} icon={SearchIcon} name="search" onChange={handleSearchChange} value={searchKey} />
                </div>

                <div className={styles.buttonContainer}>
                    <div style={{ display: "flex" }}>
                        <button className={styles.button} onClick={handleRefresh}>
                            <span style={{ display: "flex" }}>
                                <img src={RefreshIcon} alt="refresh" width={12} height={12} />
                            </span>{" "}
                            Refresh
                        </button>
                    </div>

                    <div>
                        <Button label="Add CES Survey" onClick={() => setOpen(true)} />
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className={styles.loader}>
                    <CircularProgress size={50} />
                </div>
            )}
            {!isLoading && error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}

            {!isLoading && !error && surveys.length === 0 && <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />}
            {!isLoading && !error && surveys.length > 0 && (
                <>
                    <div className={styles.userTable}>
                        <UserTable data={transformedSurveys} columns={columns} showViewColumn={false} showDeleteColumn={false} />
                    </div>
                    <div className={styles.pagination}>
                        {totalCount > SURVEY_STATUS_PAGE_LIMIT && (
                            <Pagination
                                sx={{
                                    "& .MuiPaginationItem-root.Mui-selected": {
                                        background: "#E6F2F9",
                                    },
                                }}
                                count={Math.ceil(totalCount / SURVEY_STATUS_PAGE_LIMIT)}
                                page={currentPage}
                                onChange={handlePageChange}
                                siblingCount={0}
                                showFirstButton
                                showLastButton
                            />
                        )}
                    </div>
                </>
            )}
            <CesSurveyDialog
                open={open}
                onClose={() => setOpen(false)}
                handleSurveySearch={handleSurveySearch}
                searchResult={searchResults[0]}
                error={searchError}
                handleInitiate={handleCesSurveyProcess}
            />
            <TimeoutDialog open={unauthorized} handleClick={handleAppRefresh} />
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SurveyStatus;
