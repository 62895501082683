import React from "react";

interface ButtonProps {
    label: string;
    onClick?: () => void;
    variant?: "primary" | "secondary";
    disabled?: boolean;
    icon?: React.ReactNode;
    reversed?: boolean;
    height?: string;
    width?: string;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, variant = "primary", disabled = false, icon, reversed = false, height = "2rem", width = "auto" }) => {
    let background = variant === "primary" ? "#051C2C" : "#fff";
    let color = variant === "primary" ? "#fff" : "#051C2C";
    let border = variant === "secondary" ? "1px solid #051C2C" : "none";

    if (reversed) {
        [background, color] = [color, background];
        border = "1px solid #051C2C";
    }

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className="button"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                height,
                width,
                padding: "6px 16px",
                background: disabled ? "#E6E6E6" : background,
                color: disabled ? "#b3b3b3" : color,
                border,
                borderRadius: "4px",
                cursor: disabled ? "not-allowed" : "pointer",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "MckinseySans-Light",
                lineHeight: "20px",
            }}
        >
            {icon && <span>{icon}</span>}
            {label}
        </button>
    );
};

export default Button;
