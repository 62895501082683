import { Menu, MenuItem } from "@mui/material";

import React, { useState } from "react";

import Down from "../../assets/down-icon.svg";
import { CustomCheckbox, CustomListItemText } from "../../styled/mui-styled";
import TextInput from "../input/text-input";

import styles from "./styles.module.css";

export interface Option {
    id: string;
    name: string;
}

interface DropdownWithCheckboxesProps {
    options: Option[];
    label: string;
    preSelected?: Option[];
    onSelectionChange?: (selected: Option[]) => void;
}

const DropdownWithCheckboxes: React.FC<DropdownWithCheckboxesProps> = ({ options, label, onSelectionChange, preSelected = [] }) => {
    const initialSelectedIds = preSelected.map((item) => item.id);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For controlling the menu visibility
    const [selectedValues, setSelectedValues] = useState<string[]>(initialSelectedIds); // Track selected options by their ids as strings

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (id: string) => {
        setSelectedValues((prevSelected) => {
            const newSelected = prevSelected.includes(id) ? prevSelected.filter((value) => value !== id) : [...prevSelected, id];
            if (onSelectionChange) {
                const selectedObjects = options.filter((option) => newSelected.includes(option.id));
                onSelectionChange(selectedObjects);
            }
            return newSelected;
        });
    };

    return (
        <div>
            <TextInput placeholder={label} onIconClick={handleClick} icon={Down} width="32.75rem" readOnly className={styles.input} />

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} onClick={() => handleSelect(option.id)}>
                        <CustomCheckbox checked={selectedValues.includes(option.id)} />
                        <CustomListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DropdownWithCheckboxes;
