import React from "react";

import styles from "./styles.module.css";

interface GenericBadgeProps {
    text: string;
}

const TypeTag: React.FC<GenericBadgeProps> = ({ text }) => {
    return <div className={styles.genericBadge}>{text}</div>;
};

export default TypeTag;
