import { CircularProgress, Snackbar, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AssignIcon from "../../assets/assign.svg";
import Avatar from "../../assets/avatar.svg";
import Calendar from "../../assets/calendar.svg";
import Demographic from "../../assets/demographic.svg";
import Mail from "../../assets/mail.svg";
import Question from "../../assets/question-icon.svg";
import Right from "../../assets/right-icon.svg";
import Role from "../../assets/role.svg";
import Threshold from "../../assets/threshold-icon.svg";
import User from "../../assets/user.svg";
import { ERROR_MSG, DELETE_USER_DIALOG_TEXT, DELETE_USER_MSG, DELETE_USER_ERROR, SURVEY_ACCESS_EDIT_MSG, SURVEY_ACCESS_EDIT_ERROR, SURVEY_ACCESS_EDIT_THRESHOLD_ERR } from "../../constants/constants";
import { extendedApiWrapper, UpdateSurveyDetailsRequest, UserSurveyDetailsResponse } from "../../services/api-handlers";

import { formatDate, filterAppliedDisplayNames } from "../../utils/utility-function";

import Button from "../button/button";
import CustomCard, { CardItem } from "../card/custom-card";
import DeleteDialog from "../dialog/delete-dialog";
import TimeoutDialog from "../dialog/timeout-dialog";
import MessageCard from "../message-card/message-card";

import styles from "./styles.module.css";
import SurveyPermissions from "./survey-permissions";
import UserDetails from "./user-details";

interface UserSurveyDetailsProps {
    userId: string | null;
    selectedSurveyId: string | null;
}

const customCardStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
};

const UserSurvey: React.FC<UserSurveyDetailsProps> = ({ userId, selectedSurveyId }) => {
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [userDetails, setUserDetails] = useState<CardItem[]>([]);
    const [surveyData, setSurveyData] = useState<CardItem[]>([]);
    const [userSurveyData, setUserSurveyData] = useState<UserSurveyDetailsResponse>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [unauthorized, setUnauthorized] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleClick = () => {
        setShowUserDetails(!showUserDetails);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const fetchSurveyDetails = async () => {
        if (userId && selectedSurveyId) {
            setLoading(true);
            setError(false);
            try {
                const response = await extendedApiWrapper.getUserSurveyDetails(userId, selectedSurveyId);
                if (response.data) {
                    const { user_details, threshold, questions, default_filters, assigned_by } = response.data;

                    const userData: CardItem[] = [
                        { icon: Avatar, label: "Name", value: user_details.full_name },
                        { icon: Mail, label: "Email Id", value: user_details.email },
                        { icon: Role, label: "Role", value: user_details.role_name },
                        { icon: User, label: "User created on", value: formatDate(user_details.created_on) },
                        { icon: AssignIcon, label: "User assigned by", value: assigned_by },
                        { icon: Calendar, label: "User valid till", value: formatDate(user_details.valid_till) },
                    ];

                    const surveyPermissions: CardItem[] = [
                        {
                            icon: Demographic,
                            label: "Demographic Permissions",
                            rowStyle: { display: "flex", flexDirection: "column", alignItems: "flex-start" },
                            value:
                                filterAppliedDisplayNames(default_filters).length > 0
                                    ? filterAppliedDisplayNames(default_filters).map((filter, index) => <p key={index}>{filter}</p>)
                                    : "All demographic permissions",
                        },
                        { icon: Threshold, label: "Demographic Threshold", value: String(threshold) },
                        {
                            icon: Question,
                            label: "Questions",
                            rowStyle: { display: "flex", flexDirection: "column", alignItems: "flex-start" },
                            value: Array.isArray(questions) && questions.length > 0 ? questions.map((question, index) => <p key={index}>{question.name}</p>) : "All questions",
                        },
                    ];
                    setError(false);
                    setUserSurveyData(response.data);

                    setUserDetails(userData);
                    setSurveyData(surveyPermissions);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                } else {
                    setError(true);
                }
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchSurveyDetails();
    }, [userId, selectedSurveyId]);

    const handleSubmitData = async (data: { selectedFilters: { [key: string]: string[] }; selectedQuestions: string[]; threshold: number | "" }) => {
        if (!userId || !selectedSurveyId) return;

        const requestBody: UpdateSurveyDetailsRequest = {
            user_id: userId,
            survey_id: selectedSurveyId,
            default_filters: data.selectedFilters,
            threshold: data.threshold === "" ? undefined : data.threshold,
            questions: data.selectedQuestions,
        };

        try {
            setLoading(true);
            const response = await extendedApiWrapper.updateUserSurveyDetails(requestBody);

            if (response.data) {
                setSnackbarMessage(SURVEY_ACCESS_EDIT_MSG);
                setSnackbarSeverity("success");
                fetchSurveyDetails();
                setIsEditing(false);
            } else if (response.status === 400) {
                setIsEditing(true);
                setSnackbarMessage(SURVEY_ACCESS_EDIT_THRESHOLD_ERR);
                setSnackbarSeverity("error");
            } else if (response.status === 401) {
                setUnauthorized(true);
            } else {
                setIsEditing(true);
                setSnackbarMessage(SURVEY_ACCESS_EDIT_ERROR);
                setSnackbarSeverity("error");
            }
        } catch (error) {
            setSnackbarMessage(SURVEY_ACCESS_EDIT_ERROR);
            setSnackbarSeverity("error");
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoveUser = async () => {
        if (userId) {
            try {
                const response = await extendedApiWrapper.deleteUser(userId);
                if (response.data) {
                    setOpen(false);
                    setSnackbarMessage(DELETE_USER_MSG);
                    setSnackbarSeverity("success");
                    navigate("./manage-users");
                } else {
                    setSnackbarMessage(DELETE_USER_ERROR);
                    setSnackbarSeverity("error");
                }
            } catch (error) {
                setSnackbarMessage(DELETE_USER_ERROR);
                setSnackbarSeverity("error");
            } finally {
                setSnackbarOpen(true);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleAppRefresh = () => {
        setUnauthorized(false);
        navigate(0);
    };

    return (
        <div>
            {showUserDetails ? (
                <UserDetails userId={userId} />
            ) : (
                <div className={styles.breadcrumbContainer}>
                    <button onClick={handleClick} className={styles.backButton}>
                        <span>
                            {" "}
                            <img src={Right} alt="" />
                        </span>
                        <span>Back</span>
                    </button>
                    {!loading && (
                        <div className={styles.deleteButtonContainer}>
                            <Button label="Delete User" variant="secondary" onClick={() => setOpen(true)} />
                        </div>
                    )}
                    {loading && (
                        <div className={styles.loader}>
                            <CircularProgress size={50} />
                        </div>
                    )}
                    {!loading && error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}
                    {!loading && !error && (
                        <>
                            <div>
                                <CustomCard items={userDetails} />
                            </div>
                            <div className={styles.surveyContainer}>
                                <div className={styles.surveyText}>Survey Access Permissions</div>
                                {!isEditing && (
                                    <div className={styles.assignButton}>
                                        {" "}
                                        <Button label="Edit" variant="secondary" onClick={handleEditClick} />
                                    </div>
                                )}
                            </div>
                            {isEditing ? (
                                <SurveyPermissions
                                    surveyId={selectedSurveyId}
                                    preSelectedQuestions={userSurveyData?.questions ?? []}
                                    handleEditCancel={() => setIsEditing(false)}
                                    onSubmitData={handleSubmitData}
                                    savedDemographicPermissions={userSurveyData?.default_filters ?? {}}
                                    savedThreshold={userSurveyData?.threshold ?? 0}
                                />
                            ) : (
                                <div>
                                    <CustomCard items={surveyData} customStyle={customCardStyles} />
                                </div>
                            )}
                        </>
                    )}
                    {open && (
                        <DeleteDialog
                            open={open}
                            handleCancel={handleClose}
                            onClose={handleClose}
                            highlightedText={userSurveyData?.user_details.full_name ?? ""}
                            text={DELETE_USER_DIALOG_TEXT}
                            handleRemove={handleRemoveUser}
                            removeText="Delete"
                        />
                    )}
                    <TimeoutDialog open={unauthorized} handleClick={handleAppRefresh} />
                    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </div>
            )}
        </div>
    );
};

export default UserSurvey;
