import { Grid, Pagination } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "../../assets/search_icon.svg";
import { SURVEY_LIST_PAGE_LIMIT, ERROR_MSG, NO_DATA_MSG } from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurveys, setSelectedSurveyId } from "../../store/survey-slice";
import Card from "../card/card";
import TimeoutDialog from "../dialog/timeout-dialog";
import Loader from "../loader/auth-loader";
import MessageCard from "../message-card/message-card";

import styles from "./styles.module.css";

const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { surveys, totalCount, isLoading, error, status } = useAppSelector((state) => state.surveys);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState("");
    const [unauthorized, setUnauthorized] = useState<boolean>(false);

    const debouncedFetchSurveys = useCallback(
        debounce((pageOffset, search) => {
            dispatch(fetchSurveys({ limit: SURVEY_LIST_PAGE_LIMIT, pageOffset, searchKey: search, etlStatus: "" }));
        }, 300),
        [dispatch],
    );

    useEffect(() => {
        debouncedFetchSurveys(currentPage - 1, searchKey);
        return () => {
            debouncedFetchSurveys.cancel();
        };
    }, [currentPage, searchKey, debouncedFetchSurveys]);

    useEffect(() => {
        if (status === 401) {
            setUnauthorized(true);
        }
    }, [status]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setSearchKey(value);
        setCurrentPage(1);
    };

    const handleViewClick = (surveyId: string) => {
        dispatch(setSelectedSurveyId(surveyId));
        navigate(`surveys/${surveyId}/overview`);
    };

    const handleClose = () => {
        setUnauthorized(false);
        navigate(0);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className={styles.container}>
            <div className={styles.survey}>
                <div className={styles.searchContainer}>
                    <input type="text" placeholder="Search by survey ID or name" name="search" className={styles.searchInput} onChange={handleSearchChange} />
                    <img src={SearchIcon} alt="Search Icon" />
                </div>

                {isLoading && <Loader size={50} />}

                {!isLoading && error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}

                {!isLoading && !error && surveys.length === 0 && <MessageCard title={NO_DATA_MSG.title} subtitle={NO_DATA_MSG.subtitle} text={NO_DATA_MSG.text} />}

                {!isLoading && !error && surveys.length > 0 && (
                    <Grid
                        container
                        spacing={4}
                        sx={{
                            "&.MuiGrid-root": {
                                marginTop: "10px",
                            },
                        }}
                    >
                        {surveys.map((survey) => (
                            <Grid item key={survey.survey_id} xs={12} sm={6} md={4}>
                                <Card clientName={survey.client_name} confirmitPID={survey.confirmit_pid} surveyName={survey.survey_name} surveyId={survey.survey_id} onViewClick={handleViewClick} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>
            <div className={styles.pagination}>
                {totalCount > SURVEY_LIST_PAGE_LIMIT && (
                    <Pagination
                        sx={{
                            "& .MuiPaginationItem-root.Mui-selected": {
                                background: "#E6F2F9",
                            },
                        }}
                        count={Math.ceil(totalCount / SURVEY_LIST_PAGE_LIMIT)}
                        page={currentPage}
                        onChange={handlePageChange}
                        siblingCount={0}
                        showFirstButton
                        showLastButton
                    />
                )}
            </div>
            {unauthorized && <TimeoutDialog open={unauthorized} handleClick={handleClose} />}
        </div>
    );
};

export default Home;
