/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { List, Collapse, Typography, IconButton, Box } from "@mui/material";

import React, { useState, useEffect } from "react";

import SearchIcon from "../../assets/search_icon.svg";

import { CustomCheckbox, CustomListItem, CustomListItemIcon, CustomListItemText, ViewAll, CustomChildListItem } from "../../styled/mui-styled";

import NumericalBadge from "./numerical-badge";
import styles from "./styles.module.css";

export type QuestionOption = {
    display_name: string;
    column_value: string;
    is_applied?: boolean | null;
};

export type SelectedFilter = {
    options: QuestionOption[];
    display_name: string;
};

type PresetFilterDetailsProps = {
    userPresets: Record<string, { display_name: string; options: QuestionOption[] }>;
    onCheckboxClick: (key: string, optionKey: string, checked: boolean) => void;
    editMode: boolean;
    onDisabledCheckboxClick?: () => void;
    onFiltersChange: (filters: Record<string, SelectedFilter>, formattedFilters: { [key: string]: string[] }) => void;
};

const PresetFilterDetails: React.FC<PresetFilterDetailsProps> = ({ userPresets = {}, onCheckboxClick, editMode, onDisabledCheckboxClick, onFiltersChange }) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<Record<string, string>>({});
    const [showAll, setShowAll] = useState<Record<string, boolean>>({});
    const [selectedFilters, setSelectedFilters] = useState<Record<string, SelectedFilter>>({});

    const handleFiltersChange = (newChecked: Record<string, SelectedFilter>) => {
        const formattedFilters: { [key: string]: string[] } = {};

        Object.keys(newChecked).forEach((key) => {
            const selectedOptions = newChecked[key].options.filter((option) => option.is_applied).map((option) => option.column_value);

            if (selectedOptions.length > 0) {
                formattedFilters[key] = selectedOptions;
            }
        });

        onFiltersChange(newChecked, formattedFilters); // Send both selected filters and formatted filters to the parent
    };

    useEffect(() => {
        const initialSelectedFilters = Object.keys(userPresets).reduce(
            (acc, key) => {
                const options = userPresets[key].options.map((option) => ({
                    ...option,
                    is_applied: option.is_applied ?? false,
                }));

                acc[key] = {
                    display_name: userPresets[key].display_name,
                    options,
                };
                return acc;
            },
            {} as Record<string, SelectedFilter>,
        );

        setSelectedFilters(initialSelectedFilters);
        handleFiltersChange(initialSelectedFilters);
    }, [userPresets]);

    const handleExpand = (key: string) => {
        setExpanded((prev) => (prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]));
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        setSearchValue((prev) => ({ ...prev, [key]: e.target.value.toLowerCase() }));
    };

    const handleViewAllToggle = (key: string) => {
        setShowAll((prev) => ({ ...prev, [key]: !prev[key] }));
    };
    const handleParentCheckboxClick = (key: string) => {
        const newChecked = { ...selectedFilters };
        const isAnyChildSelected = newChecked[key].options.some((opt) => opt.is_applied); // Check if any child is currently selected

        // If any child is selected, deselect all children and the parent
        if (isAnyChildSelected) {
            const updatedOptions = newChecked[key].options.map((option) => ({
                ...option,
                is_applied: false, // Uncheck all children
            }));

            newChecked[key] = {
                ...newChecked[key],
                options: updatedOptions,
            };

            // Notify deselecting action
            updatedOptions.forEach((option) => {
                onCheckboxClick(key, option.column_value, false); // Notify deselecting action
            });
        } else {
            // If no child is selected, select all children
            const updatedOptions = newChecked[key].options.map((option) => ({
                ...option,
                is_applied: true, // Check all children
            }));

            newChecked[key] = {
                ...newChecked[key],
                options: updatedOptions,
            };

            // Notify selecting action
            updatedOptions.forEach((option) => {
                onCheckboxClick(key, option.column_value, true); // Notify selecting action
            });
        }

        setSelectedFilters(newChecked);
        handleFiltersChange(newChecked);
    };

    const handleChildCheckboxClick = (key: string, option: QuestionOption) => {
        const newChecked = { ...selectedFilters };

        // Update the specific child checkbox
        const updatedOptions = newChecked[key].options.map((opt) => (opt.column_value === option.column_value ? { ...opt, is_applied: !opt.is_applied } : opt));

        newChecked[key] = {
            ...newChecked[key],
            options: updatedOptions,
        };

        setSelectedFilters(newChecked);
        handleFiltersChange(newChecked);
        onCheckboxClick(key, option.column_value, !option.is_applied);
    };

    const handleDisabledClick = () => {
        onDisabledCheckboxClick?.();
    };

    return (
        <List>
            {Object.keys(userPresets).map((key) => {
                const question = userPresets[key];
                const filteredOptions = question.options.filter((option) =>
                    String(option.display_name)
                        .toLowerCase()
                        .includes(searchValue[key] || ""),
                );
                const displayOptions = showAll[key] ? filteredOptions : filteredOptions.slice(0, 5);
                const selectedChildCount = selectedFilters[key]?.options.filter((option) => option.is_applied).length || 0;

                // Calculate parent checked state based on children
                const parentChecked = selectedChildCount > 0;

                return (
                    <React.Fragment key={key}>
                        <CustomListItem>
                            <CustomListItemIcon>
                                <Box
                                    onClick={() => {
                                        if (editMode) {
                                            handleParentCheckboxClick(key);
                                        } else {
                                            handleDisabledClick();
                                        }
                                    }}
                                >
                                    <CustomCheckbox checked={parentChecked} disabled={editMode} />
                                </Box>
                            </CustomListItemIcon>
                            <CustomListItemText primary={question.display_name} />
                            {selectedChildCount > 0 && <NumericalBadge numerator={selectedChildCount} denominator={question.options.length} />}
                            <IconButton edge="end" onClick={() => handleExpand(key)}>
                                {expanded.includes(key) ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </CustomListItem>

                        {!expanded.includes(key) && <hr className={styles.separator} />}
                        <Collapse in={expanded.includes(key)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Box className={styles.searchHead}>
                                    <img src={SearchIcon} alt="" className={styles.searchIcon} />
                                    <input type="text" placeholder="Search" className={styles.searchInput} value={searchValue[key] || ""} onChange={(e) => handleSearch(e, key)} />
                                </Box>

                                {searchValue[key] && filteredOptions.length === 0 && (
                                    <Typography variant="body2" color="textSecondary" className={styles.noResults}>
                                        No results found
                                    </Typography>
                                )}

                                {displayOptions.map((option) => (
                                    <CustomChildListItem key={option.column_value}>
                                        <CustomListItemIcon>
                                            <Box
                                                onClick={() => {
                                                    if (editMode) {
                                                        handleChildCheckboxClick(key, option);
                                                    } else {
                                                        handleDisabledClick();
                                                    }
                                                }}
                                            >
                                                <CustomCheckbox
                                                    checked={selectedFilters[key]?.options.find((opt) => opt.column_value === option.column_value)?.is_applied ?? false}
                                                    disabled={editMode}
                                                />
                                            </Box>
                                        </CustomListItemIcon>
                                        <CustomListItemText primary={option.display_name} />
                                    </CustomChildListItem>
                                ))}

                                {filteredOptions.length > 5 && !showAll[key] && (
                                    <Box className={styles.viewAll}>
                                        <ViewAll onClick={() => handleViewAllToggle(key)}>View All</ViewAll>
                                    </Box>
                                )}
                            </List>
                            <hr className={styles.separator} />
                        </Collapse>
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default PresetFilterDetails;
