import React from "react";

import styles from "./styles.module.css";

interface TextInputProps {
    value?: string | number;
    onChange?: (value: string) => void;
    placeholder?: string;
    className?: string;
    icon?: string;
    width?: string;
    height?: string;
    onIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
    readOnly?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({ value, onChange, placeholder, className, icon, onIconClick, width, readOnly, height }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <div className={styles.textInputContainer} style={{ width: width, height: height }}>
            <input type="text" value={value} onChange={handleChange} placeholder={placeholder} className={`${styles.textInput} ${className}`} readOnly={readOnly} />
            <span>
                {icon && (
                    <button className={styles.button} onClick={onIconClick}>
                        <img src={icon} alt="icon" />
                    </button>
                )}
            </span>
        </div>
    );
};

export default TextInput;
