import React from "react";

import { NavLink } from "react-router-dom";

import EditAppIcon from "../../assets/edit_app.svg";
import SurveyIcon from "../../assets/survey.svg";

import styles from "./styles.module.css";

const NavBar: React.FC = () => {
    return (
        <div className={styles.navContainer}>
            <NavLink to="manage-users" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={EditAppIcon} alt="edit app" />
                </span>
                Manage Users
            </NavLink>
            <NavLink to="survey-status" className={({ isActive }) => (isActive ? styles.activeNavItem : styles.navItem)}>
                <span className={styles.icon}>
                    <img src={SurveyIcon} alt="survey-status" />
                </span>
                Survey Status
            </NavLink>
        </div>
    );
};

export default NavBar;
