import { CircularProgress, Pagination, Snackbar, Alert } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "../../assets/search_icon.svg";
import {
    USERS_LIST_LIMIT,
    SEARCH_PLACEHOLDER,
    DELETE_USER_DIALOG_TEXT,
    ERROR_MSG,
    USER_NO_DATA,
    ADD_USER_ERROR,
    USER_EXISTS_ERROR,
    ADD_USER_MSG,
    DELETE_USER_MSG,
    DELETE_USER_ERROR,
} from "../../constants/constants";
import { extendedApiWrapper, User, UsersListResponse } from "../../services/api-handlers";
import { useAppSelector } from "../../store/hooks";
import { formatSurveyPids, formatDate, formatTimestamp } from "../../utils/utility-function";
import Button from "../button/button";
import AddUserDialog from "../dialog/add-user-dialog";
import { Option } from "../dialog/assign-survey-dialog";
import DeleteDialog from "../dialog/delete-dialog";
import TimeoutDialog from "../dialog/timeout-dialog";
import SearchInput from "../input/search-input";
import MessageCard from "../message-card/message-card";
import UserTable from "../table/user-table";
import RoleTags from "../tags/role-tags";

import styles from "./styles.module.css";
import UserDetails from "./user-details";

interface Column<T> {
    header: string;
    key: keyof T;
    render?: (value: T[keyof T], row: T) => React.ReactNode;
    sortable?: boolean;
}

const columns: Column<User>[] = [
    { header: "Name", key: "full_name" },
    { header: "Email ID", key: "email" },
    {
        header: "Role",
        key: "role_name",

        render: (role: string) => <RoleTags role={role} />,
    },
    { header: "Survey Assigned", key: "survey_pids", render: (pids: string) => <span>{formatSurveyPids(pids)}</span> },
    { header: "Validity", key: "valid_till", render: (dateString: string | null | undefined) => <span>{formatDate(dateString)}</span> },
];

const ManageUsers = () => {
    const [searchValue, setSearchValue] = useState("");
    const [userView, setUserView] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [openAddUserDialog, setOpenAddUserDialog] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [usersList, setUsersList] = useState<UsersListResponse>();
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [role, setRole] = useState<string | number>();
    const [name, setName] = useState<string>("");
    const [validity, setValidity] = useState<string>("");
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [selectedUserName, setSelectedUserName] = useState<string>("");
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
    const [unauthorized, setUnauthorized] = useState<boolean>(false);

    const navigate = useNavigate();

    const roles = useAppSelector((state) => state.roles.roles);

    const debouncedFetchUsers = useCallback(
        debounce(async (page: number, searchKey: string) => {
            try {
                setIsLoading(true);
                setError(false);
                const response = await extendedApiWrapper.listOfUsers(USERS_LIST_LIMIT, page - 1, searchKey);
                if (response.data) {
                    setUsersList(response.data);
                    setTotalUsers(response.data.total_users);
                } else if (response.status === 401) {
                    setUnauthorized(true);
                } else {
                    setError(true);
                }
            } catch (err) {
                setError(true);
            } finally {
                setIsLoading(false);
            }
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedFetchUsers(page, searchValue);
        return () => debouncedFetchUsers.cancel();
    }, [searchValue, page, debouncedFetchUsers]);

    const handleAddUser = async () => {
        try {
            const requestBody = {
                email,
                full_name: name,
                role_id: role ? String(role) : "",
                valid_till: formatTimestamp(validity),
            };

            const response = await extendedApiWrapper.addUser(requestBody);

            if (response.data) {
                setOpenAddUserDialog(false);
                setSearchValue("");
                debouncedFetchUsers(page, "");
                setSnackbarMessage(ADD_USER_MSG);
                setSnackbarSeverity("success");
            } else if (response.status === 409) {
                setSnackbarMessage(USER_EXISTS_ERROR);
                setSnackbarSeverity("error");
            } else if (response.status === 401) {
                setUnauthorized(true);
            } else {
                setSnackbarMessage(ADD_USER_ERROR);
                setSnackbarSeverity("error");
            }
        } catch (error) {
            setSnackbarMessage(ADD_USER_ERROR);
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        setPage(1);
    };

    const handleDeleteDialog = (row: User) => {
        setSelectedUserId(row.id);
        setSelectedUserName(row.full_name);
        setOpen(true);
    };

    const handleRemoveUser = async () => {
        if (selectedUserId) {
            try {
                const response = await extendedApiWrapper.deleteUser(selectedUserId);
                if (response.data) {
                    setOpen(false);
                    setSelectedUserId(null);
                    setSelectedUserName("");
                    debouncedFetchUsers(page, searchValue);
                    setSnackbarMessage(DELETE_USER_MSG);
                    setSnackbarSeverity("success");
                } else if (response.status === 401) {
                    setUnauthorized(true);
                } else {
                    setSnackbarMessage(DELETE_USER_ERROR);
                    setSnackbarSeverity("error");
                }
            } catch (error) {
                setSnackbarMessage(DELETE_USER_ERROR);
                setSnackbarSeverity("error");
            } finally {
                setSnackbarOpen(true);
            }
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleView = (row: User) => {
        setSelectedUserId(row.id);
        setUserView(true);
    };

    const handleAddUserDialog = () => {
        setOpenAddUserDialog(true);
    };
    const handleDialogClose = () => {
        setOpenAddUserDialog(false);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    const handleRoleChange = (values: Option[]) => {
        if (values.length > 0) {
            setRole(String(values[0].id));
        }
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleAppRefresh = () => {
        setUnauthorized(false);
        navigate(0);
    };

    return (
        <div className={styles.container} style={{ paddingTop: userView ? "24px" : "38px" }}>
            {userView ? (
                <UserDetails userId={selectedUserId} />
            ) : (
                <>
                    <div className={styles.inputContainer}>
                        <div className={styles.search}>
                            <SearchInput placeholder={SEARCH_PLACEHOLDER} icon={SearchIcon} name="search" onChange={handleSearchChange} value={searchValue} />
                        </div>

                        <div className={styles.buttonContainer}>
                            <Button label="Add User" onClick={handleAddUserDialog} />
                        </div>
                    </div>

                    {isLoading && (
                        <div className={styles.loader}>
                            <CircularProgress size={50} />
                        </div>
                    )}
                    {!isLoading && error && <MessageCard title={ERROR_MSG.title} subtitle={ERROR_MSG.subtitle} text={ERROR_MSG.text} />}
                    {!isLoading && !error && usersList && usersList.data.length === 0 && <MessageCard title={USER_NO_DATA.title} subtitle={USER_NO_DATA.subtitle} text={USER_NO_DATA.text} />}
                    {!isLoading && !error && usersList && usersList.data.length > 0 && (
                        <>
                            <div className={styles.userTable}>
                                <UserTable columns={columns} data={usersList.data} onDelete={handleDeleteDialog} onView={handleView} />
                            </div>
                            <div className={styles.pagination}>
                                {totalUsers > USERS_LIST_LIMIT && (
                                    <Pagination
                                        sx={{
                                            "& .MuiPaginationItem-root.Mui-selected": {
                                                background: "#E6F2F9",
                                            },
                                        }}
                                        count={Math.ceil(totalUsers / USERS_LIST_LIMIT)}
                                        page={page}
                                        onChange={handlePageChange}
                                        showFirstButton
                                        showLastButton
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {open && (
                        <DeleteDialog
                            open={open}
                            handleCancel={handleClose}
                            onClose={handleClose}
                            highlightedText={selectedUserName}
                            text={DELETE_USER_DIALOG_TEXT}
                            handleRemove={handleRemoveUser}
                            removeText="Delete"
                        />
                    )}
                    {openAddUserDialog && (
                        <AddUserDialog
                            open={openAddUserDialog}
                            onClose={handleDialogClose}
                            options={roles}
                            handleEmailChange={setEmail}
                            handleSelectRoleChange={handleRoleChange}
                            handleUserNameChange={setName}
                            handleValidTillChange={setValidity}
                            handleAddUser={handleAddUser}
                            handleCancel={handleDialogClose}
                        />
                    )}
                    <TimeoutDialog open={unauthorized} handleClick={handleAppRefresh} />
                    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </div>
    );
};
export default ManageUsers;
