import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import React, { useState } from "react";

import { formatDate } from "../../utils/utility-function";

import styles from "./styles.module.css";

export interface CardItem {
    icon: string;
    label: string;
    value: string | Date | React.ReactNode;
    isDropdown?: boolean;
    onDropdownClick?: () => void;
    dropdownType?: "calendar" | "menu";
    dropdownOptions?: string[];
    rowStyle?: React.CSSProperties;
}

interface CustomCardProps {
    items: CardItem[];
    onSaveChanges?: () => void;
    onDropdownChange?: (index: number, value: string | Date) => void;
    editIcon?: string;
    saveIcon?: string;
    dropdownIcon?: string;
    height?: string;
    customStyle?: React.CSSProperties;
}

const CustomCard: React.FC<CustomCardProps> = ({ items, onSaveChanges, onDropdownChange, editIcon, saveIcon, dropdownIcon, height, customStyle }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(null);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [activeDropdownType, setActiveDropdownType] = useState<"calendar" | "menu" | null>(null);

    const handleButtonClick = () => {
        if (isEditing) {
            onSaveChanges?.();
        }
        setIsEditing(!isEditing);
    };

    const handleDropdownClick = (index: number, event: React.MouseEvent<HTMLElement>, dropdownType: "calendar" | "menu") => {
        setMenuAnchor(event.currentTarget);
        setActiveDropdownIndex(index);
        setActiveDropdownType(dropdownType);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
        setActiveDropdownIndex(null);
        setActiveDropdownType(null);
    };

    const handleDateChange = (date: Date | string | null) => {
        if (date && activeDropdownIndex !== null) {
            onDropdownChange?.(activeDropdownIndex, formatDate(date));
            closeMenu();
        }
    };

    const handleMenuSelect = (option: string) => {
        if (activeDropdownIndex !== null) {
            onDropdownChange?.(activeDropdownIndex, option);
            closeMenu();
        }
    };

    return (
        <div className={styles.customCard} style={{ height: height || "auto" }}>
            <div className={styles.container}>
                {items.map((item, index) => (
                    <div key={index} style={customStyle}>
                        <div className={styles.header}>
                            <img src={item.icon} alt={item.label} />
                            <span className={styles.text}>{item.label}</span>
                        </div>
                        <div className={styles.rowText} style={{ ...item.rowStyle, gap: "8px" }}>
                            {item.value instanceof Date ? item.value.toLocaleDateString() : item.value}
                            {isEditing && item.isDropdown && (
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <img src={dropdownIcon} alt="dropdown" onClick={(event) => handleDropdownClick(index, event, item.dropdownType || "menu")} height={16} width={16} />
                                </span>
                            )}
                            {/* Render Dropdown (Calendar or Menu) */}
                            {activeDropdownIndex === index && activeDropdownType === "calendar" && (
                                <Menu
                                    anchorEl={menuAnchor}
                                    open={Boolean(menuAnchor)}
                                    onClose={closeMenu}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateCalendar disablePast onChange={(date) => handleDateChange(date?.toDate() || null)} />
                                    </LocalizationProvider>
                                </Menu>
                            )}

                            {activeDropdownIndex === index && activeDropdownType === "menu" && (
                                <Menu
                                    anchorEl={menuAnchor}
                                    open={Boolean(menuAnchor)}
                                    onClose={closeMenu}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                    {item.dropdownOptions?.map((option, i) => (
                                        <MenuItem key={i} onClick={() => handleMenuSelect(option)}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {(editIcon || saveIcon) && (
                <div className={styles.editContainer}>
                    <button className={styles.editButton} onClick={handleButtonClick}>
                        <img src={isEditing ? saveIcon : editIcon} alt={isEditing ? "save" : "edit"} />
                        <span className={styles.editText}>{isEditing ? "Save Changes" : "EDIT"}</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default CustomCard;
