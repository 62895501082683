/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useState, useEffect } from "react";

import Demographic from "../../assets/demographic.svg";
import Question from "../../assets/question-icon.svg";
import Threshold from "../../assets/threshold-icon.svg";
import { extendedApiWrapper, QuestionListResponse } from "../../services/api-handlers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setFilters, resetSelectedFilters } from "../../store/selected-filter-slice";
import { fetchSurveyDetails } from "../../store/survey-details-slice";
import { CustomCancelButton, CustomTranslateButton } from "../../styled/mui-styled";
import { DefaultFilters } from "../../utils/utility-function";

import TextInput from "../input/text-input";

import DemographicPermissions from "./demographic-permissions";
import DropdownWithCheckboxes, { Option } from "./dropdown";
import styles from "./styles.module.css";

interface SurveyDetailsProps {
    surveyId: string | null;
    preSelectedQuestions: Option[];
    handleEditCancel: () => void;
    onSubmitData: (data: { selectedFilters: { [key: string]: string[] }; selectedQuestions: string[]; threshold: number | "" }) => void;
    savedDemographicPermissions: DefaultFilters;
    savedThreshold: number;
}

const SurveyPermissions: React.FC<SurveyDetailsProps> = ({ surveyId, handleEditCancel, onSubmitData, preSelectedQuestions, savedDemographicPermissions, savedThreshold }) => {
    const dispatch = useAppDispatch();
    const surveyDetails = useAppSelector((state) => state.surveyDetails);
    const savedFilters = useAppSelector((state) => state.selectedFilters.selectedFilters);

    const [thresholdValue, setThresholdValue] = useState<number | "">(savedThreshold || "");
    const [questions, setQuestions] = useState<QuestionListResponse[]>([]);
    const [selectedQuestions, setSelectedQuestions] = useState<QuestionListResponse[]>(preSelectedQuestions.map((q) => ({ id: q.id, name: q.name })));
    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>(savedFilters);
    const [detailedFilters, setDetailedFilters] = useState<Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>>({});

    useEffect(() => {
        return () => {
            dispatch(resetSelectedFilters());
        };
    }, []);

    useEffect(() => {
        if (surveyId) {
            dispatch(fetchSurveyDetails(surveyId));
        }
    }, [surveyId]);

    useEffect(() => {
        if (savedDemographicPermissions) {
            // Map savedDemographicPermissions to selectedFilters and detailedFilters
            const initialSelectedFilters: { [key: string]: string[] } = {};
            const initialDetailedFilters: Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }> = {};

            Object.entries(savedDemographicPermissions).forEach(([key, category]) => {
                const appliedOptions = category.options.filter((option) => option.is_applied);

                if (appliedOptions.length > 0) {
                    initialSelectedFilters[key] = appliedOptions.map((option) => option.column_value);

                    initialDetailedFilters[key] = {
                        display_name: category.display_name,
                        options: category.options,
                    };
                }
            });

            setSelectedFilters(initialSelectedFilters);
            setDetailedFilters(initialDetailedFilters);

            // Dispatch the mapped filters to Redux store if necessary
            dispatch(setFilters({ selected: initialSelectedFilters, details: initialDetailedFilters }));
        }
    }, [savedDemographicPermissions]);

    useEffect(() => {
        const fetchQuestions = async () => {
            if (!surveyId) return;
            try {
                const response = await extendedApiWrapper.getQuestionsList(surveyId);
                if (response.data && Array.isArray(response.data)) {
                    setQuestions(response.data);
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
    }, [surveyId]);

    const handleQuestionsSelection = (selected: { id: string; name: string }[]) => {
        setSelectedQuestions(selected);
    };

    const handlePresetFilterChange = (detailedFilters: Record<string, any>, formattedFilters: { [key: string]: string[] }) => {
        setDetailedFilters(detailedFilters);
        setSelectedFilters(formattedFilters);
        dispatch(setFilters({ selected: formattedFilters, details: detailedFilters }));
    };

    const handleFiltersChange = (
        selected: { [key: string]: string[] },
        details: Record<string, { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string }>,
    ) => {
        setSelectedFilters(selected);
        setDetailedFilters(details);
        dispatch(setFilters({ selected: selected, details: details }));
    };
    const handleThresholdChange = (value: string) => {
        const numericValue = parseInt(value, 10);

        if (!isNaN(numericValue) && numericValue > 0) {
            setThresholdValue(numericValue);
        } else if (value === "") {
            setThresholdValue(0);
        }
    };

    const handleSubmit = () => {
        const questionIds = selectedQuestions.map((question) => question.id);
        const submissionData = {
            selectedFilters,
            selectedQuestions: questionIds,
            threshold: thresholdValue,
        };
        onSubmitData(submissionData);
    };

    const onCheckboxClick = (key: string, optionKey: string, checked: boolean) => {
        setDetailedFilters((prev) => {
            const updatedOptions =
                prev[key]?.options?.map((option) => {
                    if (option.column_value === optionKey) {
                        return { ...option, is_applied: checked };
                    }
                    return option;
                }) || [];

            return {
                ...prev,
                [key]: {
                    ...prev[key],
                    options: updatedOptions,
                },
            };
        });
    };

    const isSubmitDisabled = !(thresholdValue && thresholdValue > 0) && Object.keys(selectedFilters).length === 0 && selectedQuestions.length === 0;

    return (
        <>
            <div className={styles.permissionsContainer}>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicText}>Demographic Permissions</div>
                    <DemographicPermissions
                        userPresets={savedDemographicPermissions}
                        onDefaultFiltersChange={handlePresetFilterChange}
                        onCheckboxClick={onCheckboxClick}
                        editMode
                        onFiltersChange={handleFiltersChange}
                        filters={surveyDetails.data?.demographic_filters ?? {}}
                        isPreselectedFilters={Object.keys(savedDemographicPermissions).length > 0}
                    />
                </div>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicValuesHeader}>
                        <img src={Demographic} alt="" />
                        <span>Demographic Permissions</span>
                    </div>
                    <div
                        className={styles.demographicValues}
                        style={{
                            flexDirection: Object.keys(selectedFilters).length > 0 ? "column" : "row",
                        }}
                    >
                        {Object.entries(selectedFilters).length > 0
                            ? Object.entries(selectedFilters).map(([filterKey, filterValues]) => {
                                  const filterDetails = detailedFilters[filterKey];

                                  if (!filterDetails) return null; // Handle case where filterDetails might not exist

                                  const displayName = filterDetails.display_name;

                                  // Filter only the values where `is_applied` is true
                                  const appliedValues = filterDetails.options.filter((option) => option.is_applied).map((option) => option.column_value);

                                  // Check if there are any applied values
                                  if (appliedValues.length === 0) return null;

                                  const displayValues = appliedValues
                                      .slice(0, 2)
                                      .map((value) => {
                                          const valueDetails = filterDetails.options.find((option) => option.column_value === value);
                                          return valueDetails ? valueDetails.display_name : value;
                                      })
                                      .join(", ");

                                  const remainingCount = appliedValues.length - 2;

                                  return (
                                      <div key={filterKey}>
                                          <div className={styles.filterKey}>{displayName}</div>
                                          <div className={styles.filterValues}>
                                              {displayValues}
                                              {remainingCount > 0 && `, ...(+${remainingCount})`}
                                          </div>
                                      </div>
                                  );
                              })
                            : "-"}
                    </div>
                </div>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicText}>Demographic Threshold</div>
                    <TextInput placeholder="Enter Demographic Threshold" value={thresholdValue} onChange={handleThresholdChange} width="32.75rem" />
                </div>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicValuesHeader}>
                        <img src={Threshold} alt="" />
                        <span>Demographic Threshold</span>
                    </div>
                    <div className={styles.demographicValues}>{thresholdValue ? thresholdValue : "-"}</div>
                </div>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicText}>Questions</div>
                    <DropdownWithCheckboxes options={questions} label="Select Questions" onSelectionChange={handleQuestionsSelection} preSelected={preSelectedQuestions} />
                </div>
                <div className={styles.demographicContainer}>
                    <div className={styles.demographicValuesHeader}>
                        <img src={Question} alt="" />
                        <span>Questions</span>
                    </div>
                    <div className={styles.demographicValues} style={{ flexDirection: selectedQuestions.length > 0 ? "column" : "row" }}>
                        {" "}
                        {selectedQuestions.length > 0
                            ? selectedQuestions.map((item) => (
                                  <p style={{ display: "flex" }} key={item.id}>
                                      {item.name}
                                      <br />
                                  </p>
                              ))
                            : "-"}
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <CustomCancelButton variant="contained" onClick={handleEditCancel}>
                    Cancel
                </CustomCancelButton>
                <CustomTranslateButton variant="contained" onClick={handleSubmit} disabled={isSubmitDisabled}>
                    Submit
                </CustomTranslateButton>
            </div>
        </>
    );
};
export default SurveyPermissions;
