import { Box, Menu } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import React, { useState } from "react";

import AddUserIcon from "../../assets/add_user.svg";
import CalendarIcon from "../../assets/calendar.svg";
import CloseIcon from "../../assets/close_icon.svg";

import { CustomCancelButton, CustomTranslateButton, AssignDialog } from "../../styled/mui-styled";
import { formatDate } from "../../utils/utility-function";
import TextInput from "../input/text-input";
import SelectDropdown from "../select-dropdown/select-dropdown";

import { Option } from "./assign-survey-dialog";
import styles from "./styles.module.css";

interface AddUserDialogProps {
    open: boolean;
    onClose: () => void;
    handleCancel?: () => void;
    handleAddUser?: () => void;
    handleSelectRoleChange?: (values: Option[]) => void;
    handleUserNameChange?: (value: string) => void;
    handleEmailChange?: (value: string) => void;
    handleValidTillChange?: (value: string) => void;
    options: Option[];
}

const AddUserDialog: React.FC<AddUserDialogProps> = ({
    open,
    onClose,
    handleAddUser,
    handleCancel,
    handleUserNameChange,
    options,
    handleSelectRoleChange,
    handleEmailChange,
    handleValidTillChange,
}) => {
    const [userName, setUserName] = useState("");
    const [selectedRole, setSelectedRole] = useState<Option[]>([]);
    const [validTill, setValidTill] = useState("");
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [email, setEmail] = useState("");

    const isNameValid = userName.trim() !== "" && userName === userName.trim();
    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>, callback?: (value: string) => void) => (value: string) => {
        setter(value);
        if (callback) callback(value);
    };

    const handleRoleChange = (values: Option[]) => {
        setSelectedRole(values);
        handleSelectRoleChange?.(values);
    };

    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const closeMenu = () => setMenuAnchor(null);

    const handleDateChange = (date: string) => {
        const formattedDate = formatDate(date);
        if (date) setValidTill(formattedDate);
        handleValidTillChange?.(date);
        closeMenu();
    };

    const isAddUserDisabled = !isNameValid || !isEmailValid || !validTill || selectedRole.length === 0;

    return (
        <AssignDialog onClose={onClose} aria-labelledby="add-user-dialog-title" open={open} height="25.5rem">
            <button className={styles.assignClose} onClick={onClose}>
                <img src={CloseIcon} alt="Close" width={16} height={16} />
            </button>

            <Box className={styles.titleContainer}>
                <img src={AddUserIcon} alt="Add User" />
                <Box className={styles.titleText}>Add User</Box>
            </Box>

            <Box className={styles.userFieldsContainer}>
                <Box className={styles.fieldsContainer}>
                    <Box className={styles.label}>Name</Box>
                    <TextInput value={userName} onChange={handleInputChange(setUserName, handleUserNameChange)} placeholder="Enter user name" />
                </Box>

                <Box className={styles.fieldsContainer}>
                    <Box className={styles.label}>Email ID</Box>
                    <TextInput value={email} onChange={handleInputChange(setEmail, handleEmailChange)} placeholder="Enter email id" />
                </Box>

                <Box className={styles.fieldsContainer}>
                    <Box className={styles.label}>Role</Box>
                    <SelectDropdown
                        options={options}
                        labelField="name"
                        valueField="id"
                        placeholder="Select role"
                        onChange={handleRoleChange}
                        multi={false}
                        width="96%"
                        dropdownGap={0}
                        values={selectedRole}
                    />
                </Box>

                <Box className={styles.fieldsContainer}>
                    <Box className={styles.label}>Valid till</Box>
                    <TextInput value={validTill} placeholder="Set a validity date" icon={CalendarIcon} onIconClick={handleIconClick} />
                </Box>
            </Box>

            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar disablePast onChange={(date) => handleDateChange(date?.toDate() || null)} />
                </LocalizationProvider>
            </Menu>

            <Box className={styles.buttonContainer}>
                <CustomCancelButton variant="contained" onClick={handleCancel}>
                    Cancel
                </CustomCancelButton>
                <CustomTranslateButton variant="contained" onClick={handleAddUser} disabled={isAddUserDisabled}>
                    Add User
                </CustomTranslateButton>
            </Box>
        </AssignDialog>
    );
};

export default AddUserDialog;
