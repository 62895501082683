/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, List, Collapse, IconButton, Typography } from "@mui/material";

import React, { useState, useEffect } from "react";

import SearchIcon from "../../assets/search_icon.svg";

import { useAppSelector } from "../../store/hooks";
import { CustomCheckbox, CustomListItem, CustomListItemIcon, CustomListItemText, CustomChildListItem, ViewAll } from "../../styled/mui-styled";

import NumericalBadge from "./numerical-badge";

import styles from "./styles.module.css";

export type Option = {
    column_value: string;
    display_name: string;
};

export type Filter = {
    display_name: string;
    options: Option[];
};

type FiltersFormProps = {
    filters: { [key: string]: Filter };
    onFiltersChange?: (
        selectedFilters: { [key: string]: string[] },
        detailedFilters: { [key: string]: { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string } },
    ) => void;
    resetFilters?: boolean;
};

const DemographicFilter: React.FC<FiltersFormProps> = ({ filters, onFiltersChange, resetFilters }) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
    const [searchValues, setSearchValues] = useState<{ [key: string]: string }>({});
    const [showAllOptions, setShowAllOptions] = useState<{ [key: string]: boolean }>({});

    const selectedFilters = useAppSelector((state) => state.selectedFilters.selectedFilters);
    useEffect(() => {
        const initialChecked: { [key: string]: boolean } = {};
        Object.keys(filters).forEach((key) => {
            initialChecked[key] = !!selectedFilters[key]?.length;
            filters[key].options.forEach((option) => {
                initialChecked[option.column_value] = selectedFilters[key]?.includes(option.column_value) || false;
            });
        });
        setChecked(initialChecked);
        if (resetFilters) {
            const initialChecked: { [key: string]: boolean } = {};
            Object.keys(filters).forEach((key) => {
                initialChecked[key] = false;
                filters[key].options.forEach((option) => {
                    initialChecked[option.column_value] = false;
                });
            });
            setChecked(initialChecked);
        }
    }, [resetFilters, filters, selectedFilters]);
    const updateCheckedState = (newChecked: { [key: string]: boolean }) => {
        setChecked(newChecked);

        const selectedFilters: { [key: string]: string[] } = {};
        Object.keys(newChecked).forEach((key) => {
            if (newChecked[key] && filters[key]) {
                selectedFilters[key] = filters[key].options.filter((option) => newChecked[option.column_value]).map((option) => option.column_value);
            }
        });

        const detailedFilters: { [key: string]: { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string } } = {};
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                const options = filters[key].options.map((option) => ({
                    column_value: option.column_value,
                    display_name: option.display_name,
                    is_applied: newChecked[option.column_value] || null,
                }));
                detailedFilters[key] = {
                    options,
                    display_name: filters[key].display_name,
                };
            }
        });

        onFiltersChange?.(selectedFilters, detailedFilters);
    };

    const handleExpand = (key: string) => {
        const previouslyExpanded = expanded.find((item) => item !== key);
        if (previouslyExpanded) {
            setSearchValues((prev) => ({ ...prev, [previouslyExpanded]: "" }));
        }
        setExpanded(expanded.includes(key) ? expanded.filter((item) => item !== key) : [...expanded, key]);
    };

    const handleParentToggle = (parentKey: string) => {
        const newChecked = { ...checked };
        const isParentChecked = newChecked[parentKey];

        // Check if the parent key exists in the filters
        if (!filters[parentKey]) return;

        if (!isParentChecked) {
            newChecked[parentKey] = true;
            filters[parentKey].options.forEach((option) => {
                newChecked[option.column_value] = true;
            });
        } else {
            newChecked[parentKey] = false;
            filters[parentKey].options.forEach((option) => {
                newChecked[option.column_value] = false;
            });
        }

        setChecked(newChecked);
        updateCheckedState(newChecked);
    };
    const handleChildToggle = (parentKey: string, childKey: string) => {
        const newChecked = { ...checked };
        newChecked[childKey] = !newChecked[childKey];

        const childKeys = filters[parentKey].options.map((option) => option.column_value);
        const atleastOneChildSelected = childKeys.some((key) => newChecked[key]);

        newChecked[parentKey] = atleastOneChildSelected;

        setChecked(newChecked);
        updateCheckedState(newChecked);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, parentKey: string) => {
        const value = e.target.value.toLowerCase();
        setSearchValues((prev) => ({
            ...prev,
            [parentKey]: value,
        }));
    };

    const handleViewAllToggle = (filterKey: string) => {
        setShowAllOptions((prevState) => ({
            ...prevState,
            [filterKey]: true,
        }));
    };

    return (
        <List>
            {Object.keys(filters).map((key) => {
                const filter = filters[key];
                const childKeys = filter.options.map((option) => option.column_value);
                const selectedChildCount = childKeys.filter((key) => checked[key]).length;
                const totalChildCount = childKeys.length;

                const searchValue = searchValues[key] || "";
                const filteredOptions = filter.options.filter((option) => String(option.display_name).toLowerCase().includes(searchValue));
                const showViewAll = filteredOptions.length > 5 && !showAllOptions[key];

                return (
                    <React.Fragment key={key}>
                        <CustomListItem>
                            <CustomListItemIcon>
                                <CustomCheckbox checked={checked[key] || false} onChange={() => handleParentToggle(key)} disableRipple />
                            </CustomListItemIcon>
                            <CustomListItemText primary={filter.display_name} />
                            {selectedChildCount > 0 && <NumericalBadge numerator={selectedChildCount} denominator={totalChildCount} />}
                            <IconButton edge="end" onClick={() => handleExpand(key)}>
                                {expanded.includes(key) ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </CustomListItem>
                        {!expanded.includes(key) && <hr className={styles.separator} />}
                        <Collapse in={expanded.includes(key)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {filter.options && (
                                    <Box className={styles.searchHead}>
                                        <img src={SearchIcon} alt="" className={styles.searchIcon} />
                                        <input type="text" placeholder="Search" name="search" className={styles.searchInput} onChange={(e) => handleSearch(e, key)} />
                                    </Box>
                                )}

                                {searchValue && filteredOptions.length === 0 && (
                                    <Typography variant="body2" color="textSecondary" className={styles.noResults}>
                                        No results found
                                    </Typography>
                                )}

                                {filteredOptions.slice(0, showAllOptions[key] ? undefined : 5).map((option) => (
                                    <CustomChildListItem key={option.column_value}>
                                        <CustomListItemIcon>
                                            <CustomCheckbox checked={checked[option.column_value] || false} onChange={() => handleChildToggle(key, option.column_value)} />
                                        </CustomListItemIcon>
                                        <CustomListItemText primary={option.display_name} />
                                    </CustomChildListItem>
                                ))}

                                {showViewAll && (
                                    <Box className={styles.viewAll}>
                                        <ViewAll onClick={() => handleViewAllToggle(key)}>View All</ViewAll>
                                    </Box>
                                )}
                            </List>
                            <hr className={styles.separator} />
                        </Collapse>
                    </React.Fragment>
                );
            })}
        </List>
    );
};

export default DemographicFilter;
