import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Role } from "../services/api-handlers";

interface RolesState {
    roles: Role[];
}

const initialState: RolesState = {
    roles: [],
};

const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setRoles(state, action: PayloadAction<Role[]>) {
            state.roles = action.payload;
        },
    },
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
