import c from "classnames";

import React, { useEffect } from "react";

import { NavLink, useLocation } from "react-router-dom";

import AppIcon from "../../assets/app_logo.svg";
import config from "../../config/config";
import { APPLICATION_TOKEN_ERROR } from "../../constants/constants";
import { extendedApiWrapper } from "../../services/api-handlers";
import { setTokenData } from "../../store/app-token-slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";

import styles from "./styles.module.css";

const Header = () => {
    const dispatch = useAppDispatch();
    const selectedSurveyId = useAppSelector((state) => state.surveys.selectedSurveyId);
    const permissions = useAppSelector((state) => state.appToken.meta.permissions);
    const location = useLocation();

    const accessToken = window.sessionStorage.getItem("_mid-access-token");

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const response = await extendedApiWrapper.initialize();
                if (response) {
                    dispatch(
                        setTokenData({
                            accessToken: response.access_token,
                            refreshToken: response.refresh_token,
                            tokenType: response.token_type,
                            meta: {
                                role: response.meta.role,
                                permissions: response.meta.permissions,
                            },
                        }),
                    );
                }
            } catch (error) {
                console.error(APPLICATION_TOKEN_ERROR, error);
            }
        };

        fetchToken();
    }, [accessToken]);

    const isMySurveysActive = () => {
        return location.pathname.startsWith(`${config.app.REACT_APP_NAVIGATE_PATH}/surveys/${selectedSurveyId}`);
    };

    return (
        <div className={styles.appbar}>
            <img src={AppIcon} alt="logo" />
            <div className={styles.nav}>
                <NavLink to={config.app.REACT_APP_NAVIGATE_PATH} className={({ isActive }) => c(styles.headerLink, { [styles.activeHeaderLink]: isActive })} end>
                    <span className={styles.navLink}>Home</span>
                </NavLink>

                {selectedSurveyId ? (
                    <NavLink
                        to={`${config.app.REACT_APP_NAVIGATE_PATH}/surveys/${selectedSurveyId}/*`}
                        className={({ isActive }) =>
                            c(styles.headerLink, {
                                [styles.activeHeaderLink]: isMySurveysActive() || isActive,
                            })
                        }
                    >
                        <span className={styles.navLink}>My Surveys</span>
                    </NavLink>
                ) : (
                    <span className={styles.disabledLink} aria-disabled="true">
                        <span className={styles.navLink}>My Surveys</span>
                    </span>
                )}
                {permissions.includes("user_management") && (
                    <NavLink to={`${config.app.REACT_APP_NAVIGATE_PATH}/user-management`} className={({ isActive }) => c(styles.headerLink, { [styles.activeHeaderLink]: isActive })}>
                        <span className={styles.navLink}>User Management</span>
                    </NavLink>
                )}
            </div>
        </div>
    );
};

export default Header;
