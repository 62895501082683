import React, { ChangeEvent } from "react";

import styles from "./styles.module.css";

interface SearchInputProps {
    placeholder?: string;
    value?: string;
    name?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    icon?: string;
    className?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder = "Search...", value, name, onChange, icon, className }) => {
    return (
        <div className={`${styles.searchContainer} ${className || ""}`}>
            <input type="text" placeholder={placeholder} value={value} name={name} className={styles.searchInput} onChange={onChange} />
            <img src={icon} alt="Search Icon" className={styles.searchIcon} />
        </div>
    );
};

export default SearchInput;
