import React from "react";

import styles from "./styles.module.css";

interface RoleProps {
    role: string;
}

const RoleTags: React.FC<RoleProps> = ({ role }) => {
    return (
        <div className={styles.roleTags}>
            <div className={styles.roleTagsText}>{role}</div>
        </div>
    );
};
export default RoleTags;
