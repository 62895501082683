import moment from "moment-timezone";

import { WORDCLOUD_PLACEHOLDER, MAX_CHAR_LENGTH } from "../constants/constants";
import { UserPresetFilters } from "../services/api-handlers";

type FilterOption = {
    column_value: string;
    display_name: string;
    is_applied: boolean | null;
};

type FilterCategory = {
    options: FilterOption[];
    display_name: string;
};

export type DefaultFilters = {
    [key: string]: FilterCategory;
};

export const mapSliderValueToLength = (value: number): string => {
    switch (value) {
        case 0:
            return "short";
        case 100:
            return "long";
        default:
            return "Unknown";
    }
};
export const mapSummaryTypeToSentiment = (value: string): string => {
    switch (value) {
        case "positive":
            return "positive_summary";
        case "negative":
            return "negative_summary";
        case "neutral":
            return "neutral_summary";
        default:
            return "Unknown";
    }
};

export const convertToPercent = (value: number, maxValue = 100) => {
    if (value > maxValue) value = maxValue;
    if (value < 0) value = 0;
    return value / maxValue;
};
export const getLastPathSegment = (pathname: string) => {
    const pathSegments = pathname.split("/");
    return pathSegments[pathSegments.length - 1] || "";
};
export const getLabelByPathSegment = (lastSegment: string): string => {
    switch (lastSegment) {
        case "workplace-themes":
            return WORDCLOUD_PLACEHOLDER.theme;
        case "text-summarisation":
            return WORDCLOUD_PLACEHOLDER.textSummarisation;
        default:
            return "Select Question";
    }
};
export const formatNumber = (num: number): string => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + "B"; // Billions
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + "M"; // Millions
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + "K"; // Thousands
    }
    return num.toString(); // Less than 1000
};
export const getFilterById = (id: string, filtersArray: UserPresetFilters | null) => {
    if (filtersArray) {
        return filtersArray.find((filter) => filter.id === id);
    }
    return undefined;
};
export const convertObjectToOptions = <T extends object>(obj: T): { value: keyof T; label: T[keyof T] }[] => {
    return Object.keys(obj).map((key) => ({
        value: key as keyof T,
        label: obj[key as keyof T],
    }));
};
export const formatSurveyPids = (pids: string) => {
    if (!pids) {
        return "N/A";
    }
    const formatted = pids.replace(/,/g, ", ");
    return formatted.length > MAX_CHAR_LENGTH ? `${formatted.slice(0, MAX_CHAR_LENGTH)}...` : formatted;
};
export const formatDate = (dateString: Date | string | null | undefined) => {
    if (!dateString) {
        return "Unavailable";
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "Invalid";
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
};

export const formatTimestamp = (createdAt: string | Date) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedDate = moment.tz(createdAt, timeZone).format("YYYY-MM-DD");
    const formattedDateTime = `${formattedDate}T00:00:00`;

    return formattedDateTime;
};
export const filterAppliedDisplayNames = (defaultFilters: DefaultFilters): string[] => {
    const appliedFilterNames: string[] = [];

    // Iterate through each category in default_filters
    for (const filterKey in defaultFilters) {
        const filterCategory = defaultFilters[filterKey];

        // Check if options array exists and filter items where is_applied is true
        if (filterCategory.options.length > 0) {
            const appliedOptions = filterCategory.options.filter((option) => option.is_applied === true);

            // If any applied options are found, add the parent display_name to the result array
            if (appliedOptions.length > 0) {
                appliedFilterNames.push(filterCategory.display_name);
            }
        }
    }

    return appliedFilterNames;
};
export const mapStatusToType = (status: string): "successful" | "failed" | "processing" | "requested" => {
    switch (status.trim().toLowerCase()) {
        case "successful":
            return "successful";
        case "failed":
            return "failed";
        case "processing":
            return "processing";
        case "requested":
            return "requested";
        default:
            return "processing";
    }
};
