import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

import Down from "../../assets/down-icon.svg";

import DemographicFilter, { Filter } from "../filter/demographic-filter";
import PresetFilterDetails, { QuestionOption, SelectedFilter } from "../filter/peset-filter-details";
import TextInput from "../input/text-input";

import styles from "./styles.module.css";

interface DemographicPermissions {
    userPresets: Record<string, { display_name: string; options: QuestionOption[] }>;
    onCheckboxClick: (key: string, optionKey: string, checked: boolean) => void;
    editMode: boolean;
    onDefaultFiltersChange: (filters: Record<string, SelectedFilter>, formattedFilters: { [key: string]: string[] }) => void;
    isPreselectedFilters: boolean;
    filters: { [key: string]: Filter };
    onFiltersChange?: (
        selectedFilters: { [key: string]: string[] },
        detailedFilters: { [key: string]: { options: { column_value: string; display_name: string; is_applied: boolean | null }[]; display_name: string } },
    ) => void;
}

const DemographicPermissions: React.FC<DemographicPermissions> = ({ userPresets, onCheckboxClick, editMode, isPreselectedFilters, onDefaultFiltersChange, filters, onFiltersChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <TextInput placeholder="Select Demographic" onIconClick={handleClick} icon={Down} width="32.75rem" readOnly className={styles.input} />

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                transitionDuration={0}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                <MenuItem
                    sx={{
                        "&.MuiMenuItem-root": {
                            transition: "none",
                        },
                        "&:hover": {
                            background: "#ffffff",
                        },
                    }}
                >
                    {isPreselectedFilters ? (
                        <PresetFilterDetails userPresets={userPresets} editMode={editMode} onCheckboxClick={onCheckboxClick} onFiltersChange={onDefaultFiltersChange} />
                    ) : (
                        <DemographicFilter filters={filters} onFiltersChange={onFiltersChange} />
                    )}
                </MenuItem>
            </Menu>
        </div>
    );
};
export default DemographicPermissions;
