import Add from "../assets/add.svg";
import DangerIcon from "../assets/danger.svg";
import RemoveIcon from "../assets/remove.svg";
import WarningIcon from "../assets/warning.svg";

import config from "../config/config";

export const TOOLTIP_TEXT = "Wordcloud is generated from comments based on questions";
export const WORDCLOUD_PLACEHOLDER = {
    theme: "Select workplace theme",
    textSummarisation: "Select question",
};
export const SUMMARISATION_SLIDER_LABELS = ["Short", "Long"];
export const WORDCLOUD_SLIDER_LABELS = ["50", "100", "150", "200"];
export const PERSISTENT_SENTIMENT_TAGS = ["Positive", "Neutral", "Negative"];

export const APPLICATION_TOKEN_ERROR = "Error fetching application token:";
export const SURVEY_LIST_PAGE_LIMIT = 9;
export const ERROR_MSG = {
    title: "Something went wrong.",
    subtitle: "We encountered an error while trying to connect with the server.",
    text: "Please try again in a moment.",
};
export const NO_DATA = {
    title: "No results found.",
    subtitle: "The survey you’re trying to find does not exist in our system.",
    text: "Please try searching for another survey.",
};
export const THEME_HEADERS = ["Automated Workplace Themes", "Sentiment Breakdown", "Presence Percentage", "Number of Comments"];
export const THEME_COLUMN_WIDTHS = ["30%", "40%", "15%", "15%"];
export const QUESTIONS_HEADERS = ["Questions", "Sentiment Breakdown", "Response Percentage"];
export const QUESTIONS_COLUMN_WIDTHS = ["40%", "40%", "20%"];
export const PATH = `${config.app.REACT_APP_NAVIGATE_PATH}/surveys`;
export const FEEDBACK_SUBJECTS = ["Usability", "Features", "Look and feel", "Topic categorisation", "Other"];
export const FEEDBACK_SUBJECT_ERROR = "Please select at least one feedback category.";
export const FEEDBACK_DESCRIPTION_ERROR = "Please enter a feedback description.";
export const COMMENT_DUMMY_TEXT =
    "It was easy to understand the I was able to share my vision towards a common goal with the team It was easy to understand the I was able to share my vision towards a common goal with the team. ";
export const FORMAT_OPTIONS = [
    { value: "paragraph", label: "Paragraph" },
    { value: "bullet_points", label: "Bullet Points" },
    // { value: "numerics", label: "Numerics" },
];

export const TAG_TO_SUMMARY_TYPE: { [key: string]: string } = {
    overall_summary: "overall_summary",
    Positive: "positive_summary",
    Negative: "negative_summary",
    Neutral: "neutral_summary",
};
export const SENTIMENT_COLORS: { [key: string]: string } = {
    Positive: "#12A195",
    Negative: "#FF7A9F",
    Neutral: "#E6E6E6",
};
export const NO_DATA_MSG = {
    title: "No results found.",
    subtitle: "It looks like we don't have any data to display.",
    text: "Please try again later or select a different survey.",
};
export const NO_WORDCLOUD_DATA = "Currently, there isn't enough data to generate a word cloud. Please check back later or explore other questions.";
export const NO_WORDS = {
    title: "No data.",
    subtitle: "No words found for the selected criteria.",
    text: "Please try adjusting your selection or slider.",
};
export const PRESET_EDIT = {
    mainText: "Filter cannot be selected in this preset!",
    subText: "To make changes in this preset, click \u201CEdit\u201D and save changes.",
    icon: WarningIcon,
    removeIcon: RemoveIcon,
    height: "5.25rem",
    background: "#FFF3CE",
};
export const THRESHOLD_ERROR = {
    mainText: "Threshold Not Met!",
    subText: "Insufficient data for analysis. Please adjust your filters.",
    icon: WarningIcon,
    removeIcon: RemoveIcon,
    height: "5.25rem",
    background: "#FFF3CE",
};
export const DELETE_PRESET = {
    mainText: "Delete Preset?",
    subText: (
        <>
            <p>Deleting this preset will result in removing it from the platform.</p>
            <p>Are you sure you want to continue?</p>
        </>
    ),
    icon: DangerIcon,
    removeIcon: RemoveIcon,
    height: "8rem",
    background: "#FDE7E7",
};
export const SAVE_PRESET = {
    mainText: "Save Preset?",
    subText: "Preset name",
    icon: Add,
    removeIcon: RemoveIcon,
    height: "9.5rem",
    background: "#FFFFFF",
};
export const BADGE_INPUT_PLACEHOLDER = "Enter the name of preset to save";

export const RESPONDENTS = "Total respondents:";
export const TOTAL_COMMENTS = "Total comments:";
export const THRESHOLD_REPORTING = "Threshold for reporting:";
export const SUMMARY_READY = {
    title: "Summarisation response is ready!",
    text: (
        <>
            <p>Your customised summary is now ready with the</p>
            <p>selected filters.</p>
        </>
    ),
};
export const POLL_INTERVAL = 30000;
export const MAX_POLL_ATTEMPTS = 10;

export const COMMENT_PAGE_LIMIT = 24;
export const COMMENT_TRUNCATE_LENGTH = 200;
export const THEMES_LEGEND_TEXT = "Sentiment breakdown in %:";
export const COMMENTS_LEGEND_TEXT = "Sentiment breakdown:";
export const SENTIMENT_FILTER_OPTIONS = [
    { label: "Positive", value: "Positive" },
    { label: "Neutral", value: "Neutral" },
    { label: "Negative", value: "Negative" },
];
export const TRANSLATION_TEXT_COMMENTS = {
    title: "Are you sure you want to translate these comments?",
    message: "This action will translate the given comments in",
    confirmation: "Do you wish to continue with the translation?",
};
export const TRANSLATION_TEXT_SUMMARISATION = {
    title: "Are you sure you want to translate this summary?",
    message: "This action will translate the given summary in",
    confirmation: "Do you wish to continue with the translation?",
};
export const EXPORT_ERROR = "An error occurred while exporting data. Please try again later.";
export const USERS_LIST_LIMIT = 15;
export const SEARCH_PLACEHOLDER = "Search by email ID or name";

export const DELETE_USER_DIALOG_TEXT = {
    title: "Delete User?",
    firstMessage: "This action will delete the user",
    lastMessage: "from the platform.",
    confirmation: "Are you sure you want to continue?",
};
export const REMOVE_SURVEY_DIALOG_TEXT = {
    title: "Remove Survey?",
    firstMessage: "This action will remove the survey",
    lastMessage: "from the selected user’s list.",
    confirmation: "Are you sure you want to continue?",
};
export const MAX_CHAR_LENGTH = 30;
export const ADD_USER_ERROR = "Failed to add user. Please try again later.";
export const USER_EXISTS_ERROR = "User already exists. Please try adding a unique user";
export const ADD_USER_MSG = "User added successfully.";
export const DELETE_USER_MSG = "User deleted successfully.";
export const DELETE_USER_ERROR = "Failed to delete user. Please try again later.";
export const UPDATE_USER_SUCCESS_MSG = "User details updated successfully";
export const UPDATE_USER_FAILURE_MSG = "Failed to update user details. Please try again later.";
export const BREADCRUMB_ITEMS = [{ label: "Home", path: "./manage-users" }, { label: "View User" }];
export const SURVEY_LIST_LIMIT = 1000000;
export const SURVEY_ASSIGNED_MSG = "Survey assigned successfully.";
export const SURVEY_ASSIGNED_ERR = "Error occurred while assigning survey.";
export const USER_NO_DATA = {
    title: "No results found.",
    subtitle: "The user you’re trying to find does not exist in our system.",
    text: "Please try searching for another user.",
};

export const NO_ASSIGNED_SURVEY = "No assigned survey found";
export const DELETE_USER_SURVEY_MSG = "Survey removed successfully.";
export const DELETE_USER_SURVEY_ERROR = "Failed to remove survey. Please try again later.";
export const SURVEY_ACCESS_EDIT_MSG = "Survey access permissions updated successfully";
export const SURVEY_ACCESS_EDIT_ERROR = "Failed to update survey access permissions";
export const SURVEY_STATUS_PAGE_LIMIT = 15;
export const SURVEY_STATUS_SEARCH_PLACEHOLDER = "Search by survey ID or name";
export const SURVEY_ACCESS_EDIT_THRESHOLD_ERR = "Threshold Not Met! Insufficient data for analysis. Please adjust your permissions.";
export const NO_DATA_CES_SURVEY_SEARCH = "No match found for the survey ID.";
export const CES_SURVEY_SEARCH_ERR = "Error occurred during survey search";
export const PROCESSING = "Processing your request";
export const ALREADY_PROCESSING = "Survey is already under processing";
export const PROCESSING_ERROR = "Error processing CES survey";
