import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenState {
    accessToken: string | null;
    refreshToken: string | null;
    tokenType: string | null;
    meta: {
        role: string | null;
        permissions: string[];
    };
}

const initialState: TokenState = {
    accessToken: null,
    refreshToken: null,
    tokenType: null,
    meta: {
        role: null,
        permissions: [],
    },
};

const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        setTokenData(_, action: PayloadAction<TokenState>) {
            return action.payload;
        },
        clearTokenData(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.tokenType = null;
            state.meta = {
                role: null,
                permissions: [],
            };
        },
    },
});

export const { setTokenData, clearTokenData } = tokenSlice.actions;

export default tokenSlice.reducer;
