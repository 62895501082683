import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

interface BreadcrumbItem {
    label: string;
    path?: string;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    return (
        <nav aria-label="breadcrumb" className={styles.breadcrumb}>
            <ul className={styles.breadcrumbList}>
                {items.map((item, index) => (
                    <li key={index} className={styles.breadcrumbItem}>
                        {item.path ? (
                            <Link to={item.path} className={styles.breadcrumbLink}>
                                {item.label}
                            </Link>
                        ) : (
                            <span className={styles.breadcrumbCurrent}>{item.label}</span>
                        )}
                        {index < items.length - 1 && <span className={styles.breadcrumbSeparator}> &gt; </span>}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Breadcrumb;
