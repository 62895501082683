import { Box } from "@mui/material";

import React, { useEffect, useState } from "react";

import CloseIcon from "../../assets/close_icon.svg";
import ArchiveContentIcon from "../../assets/content.svg";
import DangerIcon from "../../assets/danger.svg";

import { AssignDialog, CustomTranslateButton } from "../../styled/mui-styled";
import { formatDate } from "../../utils/utility-function";

import Button from "../button/button";
import TextInput from "../input/text-input";
import TypeTag from "../tags/type-tag";

import styles from "./styles.module.css";

interface CesDialogProps {
    open: boolean;
    error: string;
    onClose: () => void;
    handleInitiate?: () => void;
    handleSurveySearch?: (surveyId: string) => void;
    searchResult?: {
        survey_name: string;
        client_name: string;
        survey_type: string;
        created_on: string;
        status: string;
        response_rate: {
            total_respondents: number;
            total_completed: number;
        };
    };
}

const CesSurveyDialog: React.FC<CesDialogProps> = ({ open, onClose, handleInitiate, handleSurveySearch, searchResult, error }) => {
    const [surveyId, setSurveyId] = useState("");

    const handleSurveyChange = (value: string): void => {
        setSurveyId(value);
    };

    const onSurveySearch = () => {
        if (handleSurveySearch) {
            handleSurveySearch(surveyId);
        }
    };

    useEffect(() => {
        return () => {
            setSurveyId("");
        };
    }, []);

    const renderHeading = (label: string, value: string | JSX.Element) => (
        <Box className={styles.headingContainer}>
            <Box className={styles.headingText}>{label}</Box>
            <Box className={styles.headingValue}>{value}</Box>
        </Box>
    );

    return (
        <AssignDialog onClose={onClose} aria-labelledby="survey-dialog-title" open={open} height="33.5rem" width="40.75rem">
            <button className={styles.surveyClose} onClick={onClose}>
                <img src={CloseIcon} alt="Close" width={24} height={24} />
            </button>

            <Box className={styles.titleContainer}>
                <img src={ArchiveContentIcon} alt="content" />
                <Box className={styles.titleText}>CES Survey</Box>
            </Box>
            <Box className={styles.detailsContainer} sx={{ marginBottom: error ? "0.5rem" : "2rem" }}>
                <TextInput placeholder="Enter Survey ID" value={surveyId} onChange={handleSurveyChange} width="25.125rem" height="2.2rem" />
                <Button label="Search" variant={!surveyId.trim() ? "primary" : "secondary"} height="2rem" onClick={onSurveySearch} disabled={!surveyId.trim()} />
            </Box>
            {error && (
                <Box className={styles.error} sx={{ marginBottom: error ? "2rem" : 0 }}>
                    <img src={DangerIcon} alt="danger" /> <span>{error}</span>
                </Box>
            )}

            <Box className={styles.fields}>
                {renderHeading("Survey name", searchResult?.survey_name ?? "-")}
                {renderHeading("Client name", searchResult?.client_name ?? "-")}
                {renderHeading("Survey type", searchResult?.survey_type ? <TypeTag text={searchResult.survey_type} /> : "-")}
                {renderHeading("Survey created on", searchResult?.created_on ? formatDate(searchResult.created_on) : "-")}
                {renderHeading("Survey status", searchResult?.status ?? "-")}
                {renderHeading("Response rate", searchResult ? `${searchResult.response_rate.total_completed} / ${searchResult.response_rate.total_respondents}` : "-")}
            </Box>

            <Box className={styles.buttonContainer}>
                <CustomTranslateButton variant="contained" onClick={handleInitiate} disabled={!searchResult}>
                    Initiate Process
                </CustomTranslateButton>
            </Box>
        </AssignDialog>
    );
};

export default CesSurveyDialog;
