import React, { useState } from "react";

import RightArrow from "../../assets/arrow-right.svg";
import DeleteIcon from "../../assets/delete_icon.svg";
import SortIcon from "../../assets/sort-icon.svg";

import styles from "./styles.module.css";

export interface Column<T> {
    header: string;
    key: keyof T;
    render?: (value: T[keyof T], row: T) => React.ReactNode;
    sortable?: boolean;
}

interface UserTableProps<T> {
    data: T[];
    columns: Column<T>[];
    onDelete?: (row: T) => void;
    onView?: (row: T) => void;
    viewText?: string;
    showViewColumn?: boolean; // Toggle view column
    showDeleteColumn?: boolean;
}

const UserTable = <T extends object>({ data, columns, onDelete, onView, viewText = "VIEW USER", showViewColumn = true, showDeleteColumn = true }: UserTableProps<T>) => {
    const [sortColumn, setSortColumn] = useState<keyof T | null>(null); // Track sorted column
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc"); // Track sort direction

    // Handle sorting on column click
    const handleSort = (column: Column<T>) => {
        if (!column.sortable) return; // Only allow sorting for sortable columns

        // Toggle sort direction if clicking on the same column
        if (sortColumn === column.key) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column.key); // Set column for sorting
            setSortDirection("asc"); // Default to ascending when a new column is selected
        }
    };

    // Sort data based on the current column and direction
    const sortedData = React.useMemo(() => {
        if (!sortColumn) return data;

        return [...data].sort((a, b) => {
            const valueA = a[sortColumn];
            const valueB = b[sortColumn];

            if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
            if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
            return 0;
        });
    }, [data, sortColumn, sortDirection]);

    return (
        <div>
            <table className={styles.customTable}>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} onClick={() => handleSort(column)} className={column.sortable ? styles.sortableHeader : ""}>
                                {column.header}
                                {column.sortable && (
                                    <span className={styles.sort}>
                                        <img src={SortIcon} alt="sort icon" />
                                    </span>
                                )}
                            </th>
                        ))}
                        {(showViewColumn || showDeleteColumn) && <th>Actions</th>}

                        {showDeleteColumn && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex}>{column.render ? column.render(row[column.key], row) : String(row[column.key])}</td>
                            ))}
                            {showViewColumn && onView && (
                                <td>
                                    <button className={styles.viewButton} onClick={() => onView(row)}>
                                        {viewText}{" "}
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img src={RightArrow} alt="arrow" height={12} width={12} loading="lazy" />
                                        </span>
                                    </button>
                                </td>
                            )}
                            {showDeleteColumn && onDelete && (
                                <td>
                                    <button className={styles.deleteButton} onClick={() => onDelete(row)}>
                                        <img src={DeleteIcon} alt="delete icon" loading="lazy" />
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserTable;
