import { Box } from "@mui/material";

import * as React from "react";

import Add from "../../assets/add-file.svg";
import Close from "../../assets/close_icon.svg";

import { CustomCancelButton, CustomTranslateButton, AssignDialog } from "../../styled/mui-styled";

import SelectDropdown from "../select-dropdown/select-dropdown";

import styles from "./styles.module.css";

export interface Option {
    id: string | number;
    name: string;
}

interface BootstrapDialogProps {
    open: boolean;
    onClose: () => void;
    handleCancel: () => void;
    handleAssign: () => void;
    handleSelectChange?: (values: Option[]) => void;
    options: Option[];
}

const AssignSurveyDialog: React.FC<BootstrapDialogProps> = ({ open, onClose, handleAssign, handleCancel, handleSelectChange, options }) => {
    const [selectedValue, setSelectedValue] = React.useState<Option[]>([]);

    const handleDropdownChange = (values: Option[]) => {
        if (values.length === 0) {
            return;
        }
        setSelectedValue(values);
        if (handleSelectChange) {
            handleSelectChange(values);
        }
    };

    return (
        <AssignDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} width="40rem">
            <button className={styles.assignClose} onClick={onClose}>
                <img src={Close} alt="Close" width={16} height={16} />
            </button>
            <Box className={styles.titleContainer}>
                <Box>
                    <img src={Add} alt="add" />
                </Box>
                <Box className={styles.titleText}>Assign Survey</Box>
            </Box>

            <Box className={styles.contentContainer}>
                <Box className={styles.surveyContainer}>
                    <Box className={styles.label}>Survey Id & Name</Box>
                    <SelectDropdown
                        options={options}
                        labelField="name"
                        valueField="id"
                        placeholder="Select Survey Id & Name"
                        onChange={handleDropdownChange}
                        multi={false}
                        width="100%"
                        dropdownGap={0}
                        values={selectedValue}
                    />
                </Box>
            </Box>

            <Box className={styles.buttonContainer}>
                <CustomCancelButton variant="contained" onClick={handleCancel}>
                    Cancel
                </CustomCancelButton>
                <CustomTranslateButton variant="contained" onClick={handleAssign} disabled={selectedValue.length === 0}>
                    Assign Survey
                </CustomTranslateButton>
            </Box>
        </AssignDialog>
    );
};

export default AssignSurveyDialog;
